import { Query, getCountFromServer } from "firebase/firestore";
import { DependencyList, useEffect, useState } from "react";
import { usePageIsVisible } from "@/util/customHooks/usePageIsVisible";

export function useCount(query?: Query, deps: DependencyList = []) {
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);

    const isPageVisible = usePageIsVisible();

    useEffect(() => {
        if (!isPageVisible) {
            return;
        }
        if (!query) {
            setCount(0);
            setLoading(false);
            return;
        }

        let mounted = true;

        (async function countDocuments() {
            try {
                setLoading(true);
                const countResult = await getCountFromServer(query);

                if (mounted) {
                    setCount(countResult.data().count);
                }
            } catch (e) {
                if (mounted) {
                    setError(e);
                    setCount(0);
                }
            } finally {
                if (mounted) {
                    setLoading(false);
                }
            }
        })();

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, isPageVisible]);

    return [count, loading, error] as const;
}
