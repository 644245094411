import { ActivityPreset, ActivityPresetPurpose, AppCompany } from "@farmact/model/src/model/AppCompany";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import dayjs from "dayjs";
import { getOrder } from "./timeTrackingUtilsData";

/**
 * Set start and end date time to start of minute to avoid confusion on receipts and analyses. Should be used when
 * manually adjusting time trackings.
 * Reason: if a time tracking has been recorded from 08:00:55 to 08:30:05, this would result in the time tracking being
 * displayed as 08:00 to 08:30 but only 29 minutes would show up on analyses and receipts which is counterintuitive.
 * @param timeTracking the time tracking to be normalized
 */
export function normalizeTimeTracking(timeTracking: TimeTracking) {
    return new TimeTracking({
        ...(timeTracking ?? {}),
        startDateTime: dayjs(timeTracking?.startDateTime).startOf("minute").toISOString(),
        endDateTime: dayjs(timeTracking?.endDateTime).startOf("minute").toISOString(),
    });
}

export async function generateTimeTrackingName(timeTracking: TimeTracking, appCompany: AppCompany): Promise<string> {
    if (timeTracking.name) {
        return timeTracking.name;
    }

    const orderPreset = appCompany.settings.orderActivityPresets.find(preset => {
        return preset.id === timeTracking.orderActivityPresetId;
    });

    if (timeTracking.order?.orderId) {
        const order = await getOrder(timeTracking.order.orderId);

        if (order?.name) {
            return `${order.name}: ${orderPreset?.name ?? "Sonstiges"}`;
        }
    } else if (timeTracking.internalActivityPresetId) {
        const internalPreset = appCompany.settings.internalActivityPresets.find(preset => {
            return preset.id === timeTracking.internalActivityPresetId;
        });

        return `Intern: ${internalPreset?.name ?? "Unbekannte Aktivität"}`;
    }

    if (orderPreset) {
        return `Auftrag: ${orderPreset.name}`;
    }

    return "Sonstiges";
}

export function getTimeTrackingOverlapsWithDay(day: Date, timeTracking: TimeTracking): boolean {
    const trackingStartMs = dayjs(timeTracking.startDateTime).valueOf();
    const trackingEndMs = dayjs(timeTracking.endDateTime).valueOf();

    const dayStartMs = dayjs(day).startOf("day").valueOf();
    const dayEndMs = dayjs(day).endOf("day").valueOf();

    // Tracking starts before and ends after day
    if (trackingStartMs <= dayStartMs && trackingEndMs >= dayEndMs) {
        return true;
    }

    const startsWithinDay = trackingStartMs >= dayStartMs && trackingStartMs <= dayEndMs;
    const endsWithinDay = trackingEndMs >= dayStartMs && trackingEndMs <= dayEndMs;

    return startsWithinDay || endsWithinDay;
}

export function filterTimeTrackingsWithoutBreak(timeTrackings: TimeTracking[], appCompany: AppCompany): TimeTracking[] {
    const orderPresetsMap = createActivityPresetAccessor(appCompany.settings.orderActivityPresets);
    const internalPresetsMap = createActivityPresetAccessor(appCompany.settings.internalActivityPresets);

    return timeTrackings.filter(timeTracking => {
        if (timeTracking.internalActivityPresetId) {
            const preset = internalPresetsMap.get(timeTracking.internalActivityPresetId);
            if (!preset) {
                return true;
            }

            return preset.purpose !== ActivityPresetPurpose.PAUSE;
        }

        if (timeTracking.orderActivityPresetId) {
            const preset = orderPresetsMap.get(timeTracking.orderActivityPresetId);
            if (!preset) {
                return true;
            }

            return preset.purpose !== ActivityPresetPurpose.PAUSE;
        }

        return true;
    });
}

export function filterTimeTrackingsOnlyBreaks(timeTrackings: TimeTracking[], appCompany: AppCompany): TimeTracking[] {
    const orderPresetsMap = createActivityPresetAccessor(appCompany.settings.orderActivityPresets);
    const internalPresetsMap = createActivityPresetAccessor(appCompany.settings.internalActivityPresets);

    return timeTrackings.filter(timeTracking => {
        if (timeTracking.internalActivityPresetId) {
            const preset = internalPresetsMap.get(timeTracking.internalActivityPresetId);
            if (!preset) {
                return false;
            }

            return preset.purpose === ActivityPresetPurpose.PAUSE;
        }

        if (timeTracking.orderActivityPresetId) {
            const preset = orderPresetsMap.get(timeTracking.orderActivityPresetId);
            if (!preset) {
                return false;
            }

            return preset.purpose === ActivityPresetPurpose.PAUSE;
        }

        return false;
    });
}

function createActivityPresetAccessor(presets: ActivityPreset[]): Map<ActivityPreset["id"], ActivityPreset> {
    return new Map(
        presets.map(preset => {
            return [preset.id, preset];
        })
    );
}
