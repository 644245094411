import { Order } from "@farmact/model/src/model/Order";
import { RentalOrder } from "@farmact/model/src/model/RentalOrder";
import dayjs from "dayjs";
import { query, where } from "firebase/firestore";
import { DependencyList, useEffect, useState } from "react";
import { Firebase } from "@/firebase";
import { mergeQueryData } from "@/firebase/helpers";
import { deduplicateById } from "@/util/deduplicateById";

export enum UseOrdersInDateRangeType {
    ORDERS,
    RENTAL_ORDERS,
}

type UseOrdersInDateRangeReturn<T extends Order | RentalOrder> = readonly [orders: T[], loading: boolean];

export function useOrdersInDateRange(
    type: UseOrdersInDateRangeType.ORDERS,
    startDate?: string,
    endDate?: string,
    deps?: DependencyList
): UseOrdersInDateRangeReturn<Order>;
export function useOrdersInDateRange(
    type: UseOrdersInDateRangeType.RENTAL_ORDERS,
    startDate?: string,
    endDate?: string,
    deps?: DependencyList
): UseOrdersInDateRangeReturn<RentalOrder>;
export function useOrdersInDateRange(
    type: UseOrdersInDateRangeType.ORDERS | UseOrdersInDateRangeType.RENTAL_ORDERS,
    startDate?: string,
    endDate?: string,
    deps: DependencyList = []
): UseOrdersInDateRangeReturn<Order | RentalOrder> {
    const [relevantOrders, setRelevantOrders] = useState<Order[] | RentalOrder[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!startDate || !endDate) {
            setRelevantOrders([]);
            return;
        }
        setLoading(true);
        const getOrdersPromise =
            type === UseOrdersInDateRangeType.ORDERS
                ? getOrdersInDateRange(startDate, endDate, type)
                : getOrdersInDateRange(startDate, endDate, type);
        getOrdersPromise.then(setRelevantOrders).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, type, ...deps]);

    return [relevantOrders, loading];
}

export async function getOrdersInDateRange(
    startDate: string,
    endDate: string,
    type: UseOrdersInDateRangeType.ORDERS
): Promise<Order[]>;
export async function getOrdersInDateRange(
    startDate: string,
    endDate: string,
    type: UseOrdersInDateRangeType.RENTAL_ORDERS
): Promise<RentalOrder[]>;
export async function getOrdersInDateRange(
    startDate: string,
    endDate: string,
    type: UseOrdersInDateRangeType
): Promise<Order[] | RentalOrder[]> {
    const datesToQuery = getQueryableDatesInRange(startDate, endDate);
    if (type === UseOrdersInDateRangeType.ORDERS) {
        const allFoundOrders = await mergeQueryData(datesToQuery, querySlice =>
            query(Firebase.instance().getAllOrders(), where("displayedDates", "array-contains-any", querySlice))
        );
        return deduplicateById(allFoundOrders);
    }

    if (type === UseOrdersInDateRangeType.RENTAL_ORDERS) {
        const allFoundRentalOrders = await mergeQueryData(datesToQuery, querySlice =>
            query(Firebase.instance().getAllRentalOrders(), where("plannedDates", "array-contains-any", querySlice))
        );
        return deduplicateById(allFoundRentalOrders);
    }

    return [];
}

export function getQueryableDatesInRange(startDate: string, endDate: string): string[] {
    let currentDateObject = dayjs(startDate);
    const endDateObject = dayjs(endDate);

    if (endDateObject.isBefore(currentDateObject)) {
        return [];
    }

    const datesToQuery: string[] = [];
    while (!currentDateObject.isAfter(endDateObject)) {
        datesToQuery.push(currentDateObject.format("YYYY-MM-DD"));
        currentDateObject = currentDateObject.add(1, "day");
    }

    return datesToQuery;
}
