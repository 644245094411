import dayjs from "dayjs";

/**
 * Returns true, if the two intervals overlap. If either of the intervals' endDateTime is before the respective
 * startDateTime, the interval is considered empty and hence this function will return false. If, however, the
 * startDateTime and the endDateTime of an interval are the same, the interval will be considered a point of time.
 * @param interval1
 * @param interval2
 */
export function intervalsOverlap(interval1: Interval, interval2: Interval) {
    const interval1Start = dayjs(interval1.startDateTime);
    const interval1End = dayjs(interval1.endDateTime);
    const interval2Start = dayjs(interval2.startDateTime);
    const interval2End = dayjs(interval2.endDateTime);

    if (interval1End.isBefore(interval1Start) || interval2End.isBefore(interval2Start)) {
        return false;
    }

    const interval1IsPoint = interval1Start.isSame(interval1End);
    const interval2IsPoint = interval2Start.isSame(interval2End);

    if (interval1IsPoint) {
        return interval2Start.isBefore(interval1Start) && interval2End.isAfter(interval1End);
    }
    if (interval2IsPoint) {
        return interval1Start.isBefore(interval2Start) && interval1End.isAfter(interval2End);
    }

    const interval1OverlapsWithStartOfInterval2 =
        interval1Start.isBefore(interval2Start) && interval1End.isAfter(interval2Start);
    const bothIntervalsStartAtSameTime = interval1Start.isSame(interval2Start);
    const interval1StartsInsideInterval2 =
        interval1Start.isAfter(interval2Start) && interval1Start.isBefore(interval2End);

    return interval1OverlapsWithStartOfInterval2 || bothIntervalsStartAtSameTime || interval1StartsInsideInterval2;
}

export type Interval = {
    startDateTime: string;
    endDateTime: string;
};
