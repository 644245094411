import clsx from "clsx";
import { Link } from "react-router-dom";
import { computed } from "@/util/functions";
import "./orderNumberLink.scss";

type OrderNumberLinkProps = {
    orderId: string;
    orderNumber: number | null | undefined;
    isRentalOrder: boolean;
    className?: string;
};

export function OrderNumberLink(props: OrderNumberLinkProps) {
    const { orderId, orderNumber, isRentalOrder, className, ...restProps } = props;

    // FIXME: find out why the import of the proper routes from farmActAppRoutes.ts breaks the build (served via preview, not the dev server)
    const orderLink = computed(() => {
        if (isRentalOrder) {
            return "/orders/rentals/detail/:rentalId".replace(":rentalId", orderId);
        } else {
            return "/orders/detail/:orderId".replace(":orderId", orderId);
        }
    });

    return (
        <Link to={orderLink} className={clsx("order-number-link", className)} {...restProps}>
            {orderNumber ?? "-"}
        </Link>
    );
}
