import {
    Describe,
    any,
    array,
    boolean,
    enums,
    literal,
    nullable,
    number,
    object,
    optional,
    string,
    union,
} from "superstruct";
import { ActivityPreset } from "./AppCompany";
import { BillingMode } from "./BillingMode";
import { Customer } from "./Customer";
import { OperatingUnit } from "./OperatingUnit";
import { ActiveTime, IdentifiablePhoto, Order } from "./Order";
import { Role } from "./Role";
import { AnyTimeTrackingMapStructure, TimeTrackingMapStructureType } from "./TimeTracking";

export enum EmployeeStatus {
    INVITED = "invited",
    ACTIVE = "active",
    REJECTED = "rejected",
    LEFT = "left",
    NEW = "new",
}

export enum ActivityType {
    ORDER = "ORDER",
    INTERNAL = "INTERNAL",
}

// Superstruct does only support record of type Record<string, T> and not Record<number, T>
export const EmployeeStruct: Describe<Omit<Employee, "yearlyVacationDays">> = object({
    active: boolean(),
    appUserId: nullable(string()),
    appUserEmail: nullable(string()),
    photo: nullable(object({ storagePath: string(), imageSrc: string() })),
    archived: boolean(),
    costPerHour: number(),
    currentActivity: nullable(
        union([
            object({
                type: literal(ActivityType.INTERNAL),
                internalActivityPresetId: string(),
                operatingUnitId: nullable(string()),
                startDateTime: string(),
                note: optional(string()),
            }),
            object({
                type: literal(ActivityType.ORDER),
                orderId: string(),
                orderNumber: nullable(number()),
                orderActivityPresetId: string(),
                customerId: nullable(string()),
                orderRunId: nullable(string()),
                startDateTime: string(),
                note: optional(string()),
                operatingUnitId: nullable(string()),
                machineVariants: array(object({ machineId: string(), variantId: string() })),
                mapStructure: nullable(
                    union([
                        object({
                            type: literal(TimeTrackingMapStructureType.FIELD),
                            fieldId: string(),
                        }),
                        object({
                            type: literal(TimeTrackingMapStructureType.TRACK),
                            trackId: string(),
                        }),
                        object({
                            type: literal(TimeTrackingMapStructureType.MARKER),
                            markerId: string(),
                        }),
                        object({
                            type: literal(TimeTrackingMapStructureType.LOADING_OR_UNLOADING_POINT),
                            loadingOrUnloadingPointId: string(),
                        }),
                    ])
                ),
            }),
        ])
    ),
    currentMonthBillingMode: enums(Object.values(BillingMode)),
    firstName: string(),
    id: string(),
    invitationTarget: nullable(string()),
    initialPassword: nullable(string()),
    lastName: string(),
    phone: string(),
    nextMonthBillingMode: enums(Object.values(BillingMode)),
    other: string(),
    role: enums(Object.values(Role)),
    status: enums(Object.values(EmployeeStatus)),
    targetHoursMonthly: number(),
    targetWorkHoursWeekly: nullable(number()),
    yearlyVacationDays: any(),
    operatingUnitIds: array(string()),
    hiringDate: nullable(string()),
    color: string(),
});

export class Employee {
    public active: boolean;
    public appUserId: string | null;
    public appUserEmail: string | null;
    public photo: IdentifiablePhoto | null;
    public archived: boolean;
    public costPerHour: number;
    public currentActivity: Activity | null;
    public currentMonthBillingMode: BillingMode;
    public firstName: string;
    public id: string;
    public invitationTarget: string | null;
    public initialPassword: string | null;
    public lastName: string;
    public phone: string;
    public nextMonthBillingMode: BillingMode;
    public other: string;
    public role: Role;
    public status: EmployeeStatus;
    /**
     * @deprecated
     */
    public targetHoursMonthly: number;
    public targetWorkHoursWeekly: number | null;
    public yearlyVacationDays: { [year: number]: number | null };
    public operatingUnitIds: Array<OperatingUnit["id"]>;
    /** Date as ISO-String */
    public hiringDate: string | null;
    /** Color as Hex string*/
    public color: string;

    constructor(initialValues?: Partial<Employee>) {
        this.active = initialValues?.active ?? true;
        this.archived = initialValues?.archived ?? false;
        this.appUserId = initialValues?.appUserId ?? null;
        this.appUserEmail = initialValues?.appUserEmail ?? null;
        this.photo = initialValues?.photo?.imageSrc && initialValues?.photo.storagePath ? initialValues.photo : null;
        this.costPerHour = initialValues?.costPerHour ?? 0;
        this.currentActivity = initialValues?.currentActivity ?? null;
        this.currentMonthBillingMode = initialValues?.currentMonthBillingMode ?? BillingMode.PER_MONTH;
        this.firstName = initialValues?.firstName ?? "";
        this.id = initialValues?.id ?? "";
        this.invitationTarget = initialValues?.invitationTarget ?? null;
        this.initialPassword = initialValues?.initialPassword ?? null;
        this.lastName = initialValues?.lastName ?? "";
        this.nextMonthBillingMode = initialValues?.nextMonthBillingMode ?? this.currentMonthBillingMode;
        this.other = initialValues?.other ?? "";
        this.role = initialValues?.role ?? Role.TEMPORARY_WORKER;
        this.yearlyVacationDays = initialValues?.yearlyVacationDays ?? {};
        this.operatingUnitIds = initialValues?.operatingUnitIds ?? [];
        this.status = initialValues?.status ?? EmployeeStatus.NEW;
        this.targetHoursMonthly = initialValues?.targetHoursMonthly ?? 0;
        this.targetWorkHoursWeekly = initialValues?.targetWorkHoursWeekly ?? null;
        this.hiringDate = initialValues?.hiringDate ?? null;
        this.phone = initialValues?.phone ?? "";
        this.color = initialValues?.color ?? "#ffce00";
    }
}

type BaseActivity = {
    type: ActivityType;
    operatingUnitId: OperatingUnit["id"] | null;
    startDateTime: string; // ISO-Datetime
    note?: string;
};

export type OrderActivity = BaseActivity & {
    type: ActivityType.ORDER;
    orderId: Order["id"];
    orderNumber: Order["orderNumber"];
    orderRunId: string | null;
    machineVariants: ActiveTime["machineVariants"];
    orderActivityPresetId: ActivityPreset["id"];
    customerId: Customer["id"] | null;
    mapStructure: AnyTimeTrackingMapStructure | null;
};

export type InternalActivity = BaseActivity & {
    type: ActivityType.INTERNAL;
    internalActivityPresetId: ActivityPreset["id"];
};

export type Activity = OrderActivity | InternalActivity;
