import { AppCompany } from "@farmact/model/src/model/AppCompany";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IonButton } from "@ionic/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { ComponentPropsWithoutRef, ReactNode, useMemo, useState } from "react";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { OrderNumberLink } from "@/components/orders/OrderNumberLink/OrderNumberLink";
import { WorkTimeTag } from "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/shared/WorkTimeTag/WorkTimeTag";
import { EditTimeTrackingModal } from "@/components/timeTracking/EditTimeTrackingModal/EditTimeTrackingModal";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { calculateTimeTrackingAmount } from "@/util/calculationUtils";
import { useCanEditTimeTracking } from "@/util/customHooks/useCanEditTimeTracking";
import "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/shared/tableCells.scss";

type TableCellsProps = ComponentPropsWithoutRef<"div"> & {
    timeTracking: TimeTracking;
    allowEmployeeChange: boolean;
};

export const TableCells = (props: TableCellsProps) => {
    const { timeTracking, allowEmployeeChange, ...restProps } = props;

    const { appCompany } = useAppCompanyContext();

    const [showEditModal, setShowEditModal] = useState(false);
    const [timeTrackingEdits, setTimeTrackingEdits] = useState<Partial<TimeTracking>>({});
    const { canEditTimeTracking, hint } = useCanEditTimeTracking(timeTracking);

    const handleTimeTrackingEdit = (updateData: Partial<TimeTracking>) => {
        setTimeTrackingEdits(prev => ({
            ...prev,
            ...updateData,
        }));
    };
    const showTimeTrackingEdit = () => {
        setShowEditModal(true);
        setTimeTrackingEdits({});
    };
    const dismissTimeTrackingEdit = () => {
        setShowEditModal(false);
        setTimeTrackingEdits({});
    };

    const editedTimeTracking = useMemo(() => {
        return new TimeTracking({
            ...props.timeTracking,
            ...timeTrackingEdits,
        });
    }, [props.timeTracking, timeTrackingEdits]);

    const [ref, setRef] = useState<HTMLDivElement | null>(null);

    return (
        <>
            <div {...restProps} className={clsx("table-cells", props.className)}>
                <div className="table-cells__cell table-cells__work-time">
                    <WorkTimeTag>{formatTime(dayjs(timeTracking.startDateTime).toDate())}</WorkTimeTag>
                    <span>-</span>
                    <WorkTimeTag>{formatTime(dayjs(timeTracking.endDateTime).toDate())}</WorkTimeTag>
                </div>
                <div className="table-cells__cell table-cells__break" />
                <div className="table-cells__cell table-cells__total">{formatWorkTime(timeTracking)}</div>
                <div className="table-cells__cell table-cells__activity">
                    {formatActivity(timeTracking, appCompany)}
                </div>
                <div className="table-cells__cell table-cells__note">{timeTracking.note}</div>
                <div className="table-cells__cell">
                    <div ref={setRef}>
                        <IonButton
                            size="small"
                            fill="clear"
                            color="primary"
                            disabled={!canEditTimeTracking}
                            onClick={showTimeTrackingEdit}>
                            <FontAwesomeIcon icon={faPencil} />
                        </IonButton>
                    </div>
                    {!canEditTimeTracking && (
                        <Tooltip trigger={ref} placement="bottom">
                            {hint}
                        </Tooltip>
                    )}
                </div>
            </div>

            <EditTimeTrackingModal
                role="edit"
                timeTracking={editedTimeTracking}
                isOpen={showEditModal}
                onDidDismiss={dismissTimeTrackingEdit}
                onFormChange={handleTimeTrackingEdit}
                onDidUpdate={dismissTimeTrackingEdit}
                allowEmployeeInput={allowEmployeeChange}
            />
        </>
    );
};

function formatTime(date: Date): string {
    return dayjs(date).format("HH:mm");
}

function formatWorkTime(timeTracking: TimeTracking): string {
    const totalTime = calculateTimeTrackingAmount([timeTracking]);

    const roundedMinutes = Math.round(totalTime);
    const hours = Math.floor(roundedMinutes / 60);
    const minutes = roundedMinutes % 60;

    return `${hours}:${minutes.toString().padStart(2, "0")} h`;
}

function formatActivity(timeTracking: TimeTracking, appCompany: AppCompany | undefined): ReactNode {
    if (timeTracking.orderActivityPresetId) {
        const preset = appCompany?.settings.orderActivityPresets.find(preset => {
            return preset.id === timeTracking.orderActivityPresetId;
        });

        if (!timeTracking.order?.orderId) {
            return preset?.name ?? "Unbekannte Aktivität";
        }

        return (
            <>
                <OrderNumberLink
                    orderId={timeTracking.order.orderId}
                    orderNumber={timeTracking.order.orderNumber}
                    isRentalOrder={false}
                />
                {preset?.name ?? "Unbekannte Aktivität"}
            </>
        );
    }

    if (timeTracking.internalActivityPresetId) {
        const preset = appCompany?.settings.internalActivityPresets.find(preset => {
            return preset.id === timeTracking.internalActivityPresetId;
        });

        return `Intern: ${preset?.name ?? "Unbekannte Aktivität"}`;
    }

    return "Unbekannt";
}
