import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { DateCell } from "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/shared/DateCell/DateCell";
import { TableCells } from "./TableCells/TableCells";
import "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/shared/tableGroupRow.scss";

type TableGroupRowProps = ComponentPropsWithoutRef<"div"> & {
    timeTracking: TimeTracking;
    allowEmployeeChange: boolean;
};

export const TableGroupRow = (props: TableGroupRowProps) => {
    const { timeTracking, allowEmployeeChange, ...restProps } = props;

    return (
        <div {...restProps} className={clsx("table-group-row", props.className)}>
            <DateCell />
            <TableCells timeTracking={timeTracking} allowEmployeeChange={allowEmployeeChange} />
        </div>
    );
};
