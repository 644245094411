import * as Tooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";
import "./pulse.scss";

type PulseProps = {
    color: "primary" | "warning" | "danger";
    tooltip?: string;
};

export function Pulse(props: PulseProps) {
    const pulseClassName = clsx("pulse", {
        "pulse--primary": props.color === "primary",
        "pulse--warning": props.color === "warning",
        "pulse--danger": props.color === "danger",
    });
    if (!props.tooltip) {
        return <div className={pulseClassName} />;
    }
    return (
        <Tooltip.Root>
            <Tooltip.Trigger className={pulseClassName} />
            <Tooltip.Portal>
                <Tooltip.Content collisionPadding={8} className="pulse__tooltip">
                    {props.tooltip}
                </Tooltip.Content>
            </Tooltip.Portal>
        </Tooltip.Root>
    );
}
