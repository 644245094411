import clsx from "clsx";
import { NumberInput } from "@/components/inputs/NumberInput/NumberInput";
import "./durationInput.scss";

type DurationInputProps = {
    value: number | null | undefined;
    onChange: (newValue: number | null) => void;

    className?: string;
};

export function DurationInput(props: DurationInputProps) {
    const [hours, minutes] = splitNumber(props.value ?? 0);

    const handleMinuteChange = (value: number | null) => {
        const newNumber = combineNumbers(hours, value ?? 0);
        props.onChange(newNumber);
    };

    const handleHoursChange = (value: number | null) => {
        const newNumber = combineNumbers(value ?? 0, minutes);
        props.onChange(newNumber);
    };

    return (
        <div className={clsx("duration-input", props.className)}>
            <NumberInput
                placeholder="Stunden"
                step={1}
                currentValue={hours || null}
                onChange={handleHoursChange}
                unit="h"
            />
            <span className="duration-input__separator">:</span>
            <NumberInput
                placeholder="Minuten"
                currentValue={minutes || null}
                step={1}
                onlyPositive={true}
                onChange={handleMinuteChange}
                unit="min"
            />
        </div>
    );
}

function splitNumber(num: number): [number, number] {
    const beforeComma = Math.sign(num) * Math.floor(Math.abs(num));
    const afterComma = Math.round((Number(num.toFixed(2).split(".")[1]) / 100) * 60);
    return [beforeComma, afterComma];
}

function combineNumbers(hours: number, minutes: number) {
    const afterComma = minutes / 60;
    // because Math.sign(-0) === -0 and we want -1 for -0, 1/-0 => -INFINITY
    const sign = Math.sign(1 / Math.sign(hours));
    return sign * (Math.abs(hours) + afterComma);
}
