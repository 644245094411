import { Overtime } from "@farmact/model/src/model/Overtime";
import { IonButton } from "@ionic/react";
import { useState } from "react";
import * as Item from "@/components/FaItem";
import { FaItemGroup } from "@/components/FaItemGroup/FaItemGroup";
import * as Modal from "@/components/FaModal";
import { DateInput } from "@/components/inputs/DateInput/DateInput";
import { DurationInput } from "@/components/inputs/DurationInput/DurationInput";
import { Textarea } from "@/components/inputs/Textarea/Textarea";
import { useTimeTrackingsOverviewContext } from "@/components/timeTracking/TimeTrackingsOverviewContext/useTimeTrackingsOverviewContext";
import { useToastContext } from "@/components/ToastContext";
import { Firebase } from "@/firebase";
import { recordError } from "@/util/recordError";
import "./editOvertimeModal.scss";

type OvertimeModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
    overtime: Overtime;
};

export const EditOvertimeModal = (props: OvertimeModalProps) => {
    const { reloadTotalOvertime } = useTimeTrackingsOverviewContext();
    const { onMessage } = useToastContext();
    const [overtime, setOvertime] = useState(new Overtime());
    const [previousOpen, setPreviousOpen] = useState(false);

    if (!previousOpen && props.isOpen) {
        setPreviousOpen(true);
        setOvertime({ ...props.overtime });
    }

    if (previousOpen && !props.isOpen) {
        setPreviousOpen(false);
        setOvertime(new Overtime());
    }

    const handleSaveOverTime = () => {
        try {
            Firebase.instance()
                .updateOverTime(overtime.id, { ...overtime })
                .then(reloadTotalOvertime);
            onMessage("Überstunden gespeichert.", "success");
            props.onDismiss();
        } catch (error: any) {
            onMessage("Fehler beim speichern. Versuche es später erneut.", "danger");
            recordError("Error while trying to save overtime", { overtime, error });
        }
    };

    const handleOvertimeChange = (changedValues: Partial<Overtime>) => {
        setOvertime(prev => ({ ...prev, ...changedValues }));
    };

    const handleOvertimeDelete = () => {
        try {
            Firebase.instance().deleteOvertime(overtime.id).then(reloadTotalOvertime);
            onMessage("Überstunden gelöscht", "success");
            props.onDismiss();
        } catch (error: any) {
            onMessage("Fehler beim löschen. Versuche es später erneut.", "danger");
            recordError("Error while trying to delete overtime", { overtime, error });
        }
    };

    return (
        <Modal.Root isOpen={props.isOpen} onDismiss={props.onDismiss} className="edit-overtime-modal">
            <Modal.Header>Stundenkonto bearbeiten</Modal.Header>
            <Modal.Content>
                <FaItemGroup>
                    <Item.Root>
                        <Item.Label>Datum</Item.Label>
                        <Item.Content>
                            <DateInput
                                value={new Date(overtime.date)}
                                onChange={value => handleOvertimeChange({ date: value?.toISOString() })}
                                clearable={false}
                            />
                        </Item.Content>
                    </Item.Root>
                    <Item.Root>
                        <Item.Label tooltip="Negative Werte reduzieren das Stundenkonto.">Überstunden</Item.Label>
                        <Item.Content>
                            <DurationInput
                                value={overtime.amount}
                                onChange={amount => {
                                    handleOvertimeChange({ amount: amount ?? 0 });
                                }}
                            />
                        </Item.Content>
                    </Item.Root>
                    <Item.Root>
                        <Item.Label>Notiz</Item.Label>
                        <Item.Content>
                            <Textarea
                                placeholder="Notiz"
                                value={overtime.note}
                                onChange={event => {
                                    handleOvertimeChange({ note: event.currentTarget.value });
                                }}
                            />
                        </Item.Content>
                    </Item.Root>
                </FaItemGroup>
            </Modal.Content>
            <Modal.Footer>
                <IonButton color="danger" onClick={handleOvertimeDelete}>
                    Löschen
                </IonButton>
                <IonButton color="dark" onClick={props.onDismiss}>
                    Verwerfen
                </IonButton>
                <IonButton color="primary" onClick={handleSaveOverTime}>
                    Speichern
                </IonButton>
            </Modal.Footer>
        </Modal.Root>
    );
};
