import { Machine } from "@farmact/model/src/model/Machine";
import { ComboboxItem } from "@mantine/core";
import { SelectOptionSorter } from "@/util/select";

export function createMachineSelectItem(machine: Machine): ComboboxItem {
    return {
        value: machine.id,
        label: machine.name,
        disabled: !machine.active || machine.archived,
    };
}

export const defaultSelectableMachinesSorter: SelectOptionSorter<Machine> = (a, b) => {
    return a.name.localeCompare(b.name);
};
