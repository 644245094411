import { Machine, MachineVariantIdentifier } from "@farmact/model/src/model/Machine";
import { forwardRef } from "react";
import * as Collapsible from "@/components/FaCollapsible";
import { FaCollapsibleRef } from "@/components/FaCollapsible/FaCollapsibleRoot/FaCollapsibleRoot";
import { MachineVariantsInputItem } from "@/components/inputs/MachineVariantsInputItem/MachineVariantsInputItem/MachineVariantsInputItem";
import { SingleValidationError } from "@/util/customHooks/validation/useValidation";
import "./collapsibleMachineVariantsInputItem.scss";

type MachineVariantsInputItemProps = {
    availableMachines: Machine[];
    selectedMachineVariants: MachineVariantIdentifier[];
    onChange: (updatedMachines: MachineVariantIdentifier[]) => void;
    fixedMachines?: Machine["id"][];
    error?: SingleValidationError;
};

export const CollapsibleMachineVariantsInputItem = forwardRef<FaCollapsibleRef, MachineVariantsInputItemProps>(
    (props: MachineVariantsInputItemProps, forwardedRef) => {
        return (
            <div className="collapsible-machine-variants-input-item">
                <Collapsible.Root ref={forwardedRef}>
                    <Collapsible.Trigger>
                        <div className="collapsible-machine-variants-input-item__header">
                            <span>Während der Erfassung aktive Maschinen ({props.selectedMachineVariants.length})</span>
                            <Collapsible.Icon className="collapsible-machine-variants-input-item__toggle-icon" />
                        </div>
                    </Collapsible.Trigger>
                    <Collapsible.Body className="collapsible-machine-variants-input-item__body">
                        <MachineVariantsInputItem {...props} />
                    </Collapsible.Body>
                </Collapsible.Root>
            </div>
        );
    }
);
