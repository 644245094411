import { Absence } from "@farmact/model/src/model/Absence";
import { AppCompany } from "@farmact/model/src/model/AppCompany";
import { Activity, ActivityType, Employee } from "@farmact/model/src/model/Employee";
import { Overtime } from "@farmact/model/src/model/Overtime";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { round } from "@farmact/utils/src/numberUtils";
import { EmployeeTotalOvertimeResult } from "./customHooks/employees/useEmployeeTotalOvertime";
import { _getEmployeeAbsenceMinutes } from "./employees/_getEmployeeAbsenceMinutes";
import { _getEmployeePauseMinutes } from "./employees/_getEmployeePauseMinutes";
import { _getEmployeeTargetHours } from "./employees/_getEmployeeTargetHours";
import { _getEmployeeTotalOvertimeMinutes } from "./employees/_getEmployeeTotalOvertimeMinutes";
import { _getEmployeeWorkedMinutes } from "./employees/_getEmployeeWorkedMinutes";
import { DateRange } from "./timeUtils";

export type GetEmployeeTimesSummaryParams = {
    employee: Employee | undefined;
    interval: DateRange;
    timeTrackings: TimeTracking[];
    overtimes: Overtime[];
    absences: Absence[];
    totalOvertimeHours: EmployeeTotalOvertimeResult | undefined;
    appCompany: AppCompany;
};

export type EmployeeTimesSummary = {
    targetHours: number;
    absenceMinutes: number;
    workMinutes: number;
    pauseMinutes: number;
    overtimeMinutes: number;
    totalMinutes: number;
    totalOvertimeMinutes: EmployeeTotalOvertimeResult | undefined;
};

export function getEmployeeTimesSummary(params: GetEmployeeTimesSummaryParams): EmployeeTimesSummary {
    const absenceMinutes = _getEmployeeAbsenceMinutes(params);
    const targetHours = _getEmployeeTargetHours(params) - absenceMinutes / 60;
    const workedMinutes = _getEmployeeWorkedMinutes(params);
    const pauseMinutes = _getEmployeePauseMinutes(params);
    const totalOvertimeMinutes = _getEmployeeTotalOvertimeMinutes(params);

    const overtimeMinutes = workedMinutes - targetHours * 60;
    const totalMinutes = workedMinutes + pauseMinutes;

    return {
        targetHours,
        absenceMinutes: round(absenceMinutes),
        workMinutes: round(workedMinutes),
        pauseMinutes: round(pauseMinutes),
        overtimeMinutes: round(overtimeMinutes),
        totalMinutes: round(totalMinutes),
        totalOvertimeMinutes,
    };
}

export function formatCurrentActivityName(
    currentActivity: Activity,
    appCompany: AppCompany | undefined
): string | undefined {
    if (!currentActivity) {
        return;
    }

    switch (currentActivity.type) {
        case ActivityType.ORDER: {
            const preset = appCompany?.settings.orderActivityPresets.find(preset => {
                return preset.id === currentActivity.orderActivityPresetId;
            });

            return preset?.name;
        }
        case ActivityType.INTERNAL: {
            const preset = appCompany?.settings.internalActivityPresets.find(preset => {
                return preset.id === currentActivity.internalActivityPresetId;
            });

            return preset?.name;
        }
    }
}
