import { Employee } from "@farmact/model/src/model/Employee";
import { ComboboxItem } from "@mantine/core";
import { getPersonsFullName } from "@/util/getPersonsFullName";
import { SelectOptionSorter } from "@/util/select";

export function createEmployeeSelectItem(employee: Employee, options?: { disableInactive?: boolean }): ComboboxItem {
    const disableInactive = options?.disableInactive ?? true;
    const disabled = employee.archived || (disableInactive && !employee.active);
    return {
        value: employee.id,
        label: getPersonsFullName(employee),
        disabled,
    };
}

export const defaultSelectableEmployeesSorter: SelectOptionSorter<Employee> = (a, b) => {
    return getPersonsFullName(a).localeCompare(getPersonsFullName(b));
};
