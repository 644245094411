export enum ServiceCategory {
    TRANSPORT_LOGISTICS = "TRANSPORT_LOGISTICS",
    TILLAGE_SOWING = "TILLAGE_SOWING",
    FERTILIZATION = "FERTILIZATION",
    CROP_PROTECTION = "CROP_PROTECTION",
    HARVEST = "HARVEST",
    HARVEST_SELF_DRIVING = "HARVEST_SELF_DRIVING",
    FIELD_MAINTENANCE = "FIELD_MAINTENANCE",
    MUNICIPAL_WORK = "MUNICIPAL_WORK",
    EARTHWORKS_CONSTRUCTION = "EARTHWORKS_CONSTRUCTION",
    OTHER = "OTHER",
    FORESTRY = "FORESTRY",
    WORKSHOP_WORK = "WORKSHOP_WORK",
    INTERNAL = "INTERNAL",
    TRACTOR_RENTAL = "TRACTOR_RENTAL",
    MACHINE_RENTAL = "MACHINE_RENTAL",
    MANUAL_WORK = "MANUAL_WORK",
}

export const getNameForServiceCategory = (serviceCategory: ServiceCategory) => {
    switch (serviceCategory) {
        case ServiceCategory.TRANSPORT_LOGISTICS:
            return "Transport / Logistik";
        case ServiceCategory.TILLAGE_SOWING:
            return "Bodenbearbeitung / Aussaat";
        case ServiceCategory.FERTILIZATION:
            return "Düngung";
        case ServiceCategory.CROP_PROTECTION:
            return "Pflanzenschutz";
        case ServiceCategory.HARVEST:
            return "Ernte";
        case ServiceCategory.HARVEST_SELF_DRIVING:
            return "Ernte (Selbstfahrer)";
        case ServiceCategory.FIELD_MAINTENANCE:
            return "Feldpflege";
        case ServiceCategory.MUNICIPAL_WORK:
            return "Kommunalarbeiten";
        case ServiceCategory.EARTHWORKS_CONSTRUCTION:
            return "Erdbau / Tiefbau";
        case ServiceCategory.FORESTRY:
            return "Forst / Holz";
        case ServiceCategory.WORKSHOP_WORK:
            return "Werkstattarbeiten";
        case ServiceCategory.INTERNAL:
            return "Interne Tätigkeiten";
        case ServiceCategory.TRACTOR_RENTAL:
            return "Traktorenverleih";
        case ServiceCategory.MACHINE_RENTAL:
            return "Maschinenverleih";
        case ServiceCategory.MANUAL_WORK:
            return "Handarbeiten";
        case ServiceCategory.OTHER:
        default:
            return "Sonstiges";
    }
};

export const parseServiceCategory = (categoryString?: string) => {
    if (!categoryString) {
        return ServiceCategory.OTHER;
    }
    if (Object.keys(ServiceCategory).includes(categoryString)) {
        return ServiceCategory[categoryString as keyof typeof ServiceCategory];
    } else {
        return ServiceCategory.OTHER;
    }
};
