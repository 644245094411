import { Customer } from "@farmact/model/src/model/Customer";
import { query, where } from "firebase/firestore";
import { useMemo } from "react";
import { useUserContext } from "@/components/authentication/Session/useUserContext";
import { THRESHOLD_FOR_LOADING_CUSTOMERS } from "@/components/inputs/customerInputs/customerInputsUtils";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import { getQueryForCustomersWithIds } from "@/firebase/utils/useMergedQueryData/queryGenerators/getQueryForCustomersWithIds";
import { useMergedQueryData } from "@/firebase/utils/useMergedQueryData/useMergedQueryData";
import { getEmployeeHasAccess } from "@/util/accessUtils";
import { useCustomersCount } from "@/util/customHooks/customers/useCustomersCount";
import { useStableIdArray } from "@/util/customHooks/useStableArray";

type UseCustomersForCustomerSelectParams = {
    controlledCustomers: Customer[] | undefined;
    currentSelection: Customer["id"][];
    search: string;
    filter?: (customer: Customer) => boolean;
};

type UseCustomersForCustomerSelectReturn = readonly [customer: Customer[], loading: boolean];

export function useCustomersForCustomerSelect(
    params: UseCustomersForCustomerSelectParams
): UseCustomersForCustomerSelectReturn {
    const shouldFetchOptions = !params.controlledCustomers;

    const { employee: loggedInEmployee } = useUserContext();

    const { count: customersCount, loading: customersCountLoading } = useCustomersCount();

    const companyHasFewCustomers = !customersCountLoading && customersCount <= THRESHOLD_FOR_LOADING_CUSTOMERS;
    const lowercasedSearch = params.search.substring(0, 10).toLowerCase();

    const queryForAllCustomers = useMemo(() => {
        if (!shouldFetchOptions || !companyHasFewCustomers) {
            return undefined;
        }
        return Firebase.instance().getAllCustomers();
    }, [companyHasFewCustomers, shouldFetchOptions]);

    const [allCustomers, allCustomersLoading] = useCollectionData(queryForAllCustomers, [queryForAllCustomers]);

    const queryToFetchSearchedCustomers = useMemo(() => {
        if (!shouldFetchOptions || lowercasedSearch.length < 3 || companyHasFewCustomers) {
            return undefined;
        }
        return query(
            Firebase.instance().getAllCustomers(),
            where("_searchableSubstrings", "array-contains", lowercasedSearch)
        );
    }, [lowercasedSearch, shouldFetchOptions, companyHasFewCustomers]);
    const [searchedCustomers, searchedCustomersLoading] = useCollectionData(queryToFetchSearchedCustomers, [
        queryToFetchSearchedCustomers,
    ]);

    const stableCurrentSelectionArray = useStableIdArray(params.currentSelection);
    const [alreadySelectedCustomers, alreadySelectedCustomersLoading] = useMergedQueryData(
        stableCurrentSelectionArray,
        getQueryForCustomersWithIds
    );

    const baseCompatibleCustomers =
        params.controlledCustomers ?? (companyHasFewCustomers ? allCustomers : searchedCustomers);

    const filteredCompatibleCustomers = baseCompatibleCustomers.filter(customer => {
        const customFilterResult = params.filter?.(customer) ?? true;
        const searchFilterResult = searchFilter(params.search, customer);

        return (
            customFilterResult &&
            searchFilterResult &&
            getEmployeeHasAccess(loggedInEmployee, customer.operatingUnitIds)
        );
    });

    const deduplicatedCustomers = [...alreadySelectedCustomers, ...filteredCompatibleCustomers].filter(
        (outerCustomer, index, allCustomers) => {
            return allCustomers.findIndex(innerCustomer => outerCustomer.id === innerCustomer.id) === index;
        }
    );

    return [
        deduplicatedCustomers,
        searchedCustomersLoading || alreadySelectedCustomersLoading || allCustomersLoading,
    ] as const;
}

function searchFilter(search: string, customer: Customer): boolean {
    search = search.trim();
    if (search === "") {
        return true;
    }

    const searchable: string[] = [customer.name, customer.alias];

    const primaryContactPerson = customer.contactPersons.find(contactPerson => {
        return contactPerson.isPrimary;
    });
    if (primaryContactPerson) {
        searchable.push(primaryContactPerson.firstName, primaryContactPerson.lastName);
    }

    const searchableText = searchable.filter(Boolean).join("").toLowerCase();

    return searchableText.includes(search.toLowerCase());
}
