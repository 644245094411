import clsx from "clsx";
import { ComponentPropsWithoutRef, useState } from "react";
import { DayGroupedWorkTimes, TableGroupContext } from "./TableGroupContext";

type TableGroupProps = ComponentPropsWithoutRef<"div"> & {
    group: DayGroupedWorkTimes;
};

export const TableGroup = (props: TableGroupProps) => {
    const { group, ...restProps } = props;

    const [open, setOpen] = useState(false);

    return (
        <TableGroupContext.Provider value={{ group, open, setOpen }}>
            <div {...restProps} className={clsx("table-group", props.className)} />
        </TableGroupContext.Provider>
    );
};
