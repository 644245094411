import { useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { useState } from "react";

export function usePageIsVisible() {
    const [pageVisible, setPageVisible] = useState(true);

    useIonViewDidLeave(() => {
        setPageVisible(false);
    });

    useIonViewDidEnter(() => {
        setPageVisible(true);
    });
    return pageVisible;
}
