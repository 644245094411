import { Role } from "@farmact/model/src/model/Role";
import { useCallback, useEffect, useState } from "react";
import { onlyRoles } from "@/components/authentication/authorizations";
import { useUserContext } from "@/components/authentication/Session/useUserContext";

export type UseRoleReturn = {
    role: Role | undefined;
    isLoading: boolean;
    error: any;
    only: (...roles: Role[]) => boolean;
};

export function useRole(): UseRoleReturn {
    const [role, setRole] = useState<Role | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [error] = useState<any>(null);

    const claims = useUserContext().customClaims;

    useEffect(() => {
        if (!claims) {
            return;
        }

        if (claims.defaultCompanyId && claims.roles?.[claims.defaultCompanyId]) {
            setRole(claims.roles[claims.defaultCompanyId]);
            setLoading(false);
            return;
        }

        if (!claims.defaultCompanyId && Object.entries(claims?.customerIds ?? {}).length > 0) {
            setRole(Role.CUSTOMER);
            setLoading(false);
            return;
        }

        setRole(Role.NOTHING);
        setLoading(false);
    }, [claims]);

    const only = useCallback(
        (...roles: Role[]) => {
            const checkAuthorized = onlyRoles(...roles);
            return checkAuthorized(role);
        },
        [role]
    );

    return {
        role,
        isLoading: loading,
        error,
        only,
    };
}
