import { Machine, MachineVariantIdentifier } from "@farmact/model/src/model/Machine";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { IonButton } from "@ionic/react";
import { produce } from "immer";
import { FaIcon } from "@/components/icons/FaIcon/FaIcon";
import { MachineVariantItem } from "@/components/inputs/MachineVariantsInputItem/MachineVariantItem/MachineVariantItem";
import { useToastContext } from "@/components/ToastContext";
import { SingleValidationError } from "@/util/customHooks/validation/useValidation";
import { recordError } from "@/util/recordError";
import "./machineVariantsInputItem.scss";

type MachineVariantsInputItemProps = {
    availableMachines: Machine[];
    selectedMachineVariants: MachineVariantIdentifier[];
    onChange: (updatedMachines: MachineVariantIdentifier[]) => void;
    fixedMachines?: Machine["id"][];
    error?: SingleValidationError;
};

export function MachineVariantsInputItem(props: MachineVariantsInputItemProps) {
    const { onMessage } = useToastContext();

    const handleMachineDeletion = (deletedMachineId: MachineVariantIdentifier["machineId"]) => {
        props.onChange(props.selectedMachineVariants.filter(variant => variant.machineId !== deletedMachineId));
    };

    const handleVariantChange = (oldVariant: MachineVariantIdentifier, updatedVariant: MachineVariantIdentifier) => {
        const updatedVariants = produce(props.selectedMachineVariants, machineVariantsDraft => {
            for (const variant of machineVariantsDraft) {
                if (variant.machineId === oldVariant.machineId) {
                    variant.machineId = updatedVariant.machineId;
                    variant.variantId = updatedVariant.variantId;
                }
            }
        });
        props.onChange(updatedVariants);
    };

    const getAvailableMachines = (variant: MachineVariantIdentifier) => {
        return props.availableMachines.filter(machine => {
            if (machine.id === variant.machineId) {
                return true;
            }
            const currentlySelectedMachineIds = props.selectedMachineVariants.map(variant => variant.machineId);
            return !currentlySelectedMachineIds.includes(machine.id);
        });
    };

    const machinesWithoutEntry = props.availableMachines.filter(
        machine => !props.selectedMachineVariants.map(variant => variant.machineId).includes(machine.id)
    );

    const handleAddMachineClick = () => {
        if (machinesWithoutEntry.length > 0 && machinesWithoutEntry[0].variants.length > 0) {
            props.onChange([
                ...props.selectedMachineVariants,
                {
                    machineId: machinesWithoutEntry[0].id,
                    variantId: machinesWithoutEntry[0].variants[0].id,
                },
            ]);
        } else {
            onMessage("Da hat etwas nicht funktioniert. Bitte versuche es später erneut.", "danger");
            recordError("Could not add machine variant.", { machinesWithoutEntry });
        }
    };

    return (
        <div>
            {props.selectedMachineVariants.map(machineVariant => (
                <MachineVariantItem
                    key={machineVariant.machineId}
                    availableMachines={getAvailableMachines(machineVariant)}
                    value={machineVariant}
                    onChange={updatedVariant => handleVariantChange(machineVariant, updatedVariant)}
                    onDelete={() => handleMachineDeletion(machineVariant.machineId)}
                    error={machineVariant.variantId ? undefined : props.error}
                    machineFixed={props.fixedMachines?.includes(machineVariant.machineId)}
                />
            ))}
            {machinesWithoutEntry.length > 0 && (
                <IonButton className="machine-variants-input-item__add-button" onClick={handleAddMachineClick}>
                    <FaIcon icon={faPlus} spacing="right" />
                    <span>Maschine hinzufügen</span>
                </IonButton>
            )}
        </div>
    );
}
