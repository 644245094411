import dayjs from "dayjs";
import { CustomerPaymentInfos, DEFAULT_CUSTOMER_PAYMENT_INFOS } from "./Customer";
import { DatevTransfer } from "./DatevTransfer";
import { MailTraceMeta } from "./MailTraceMeta";
import { PhotoSize } from "./PhotoSize";
import { Receipt } from "./Receipt";
import { notNullish } from "../utils/array";
import { generateSearchableSubstrings } from "../utils/generateSearchableSubstrings";

export const DEFAULT_VAT_PERCENT_POINTS = 19;

export enum BillStatus {
    DRAFT = "DRAFT",
    OPEN = "OPEN",
    PAID = "PAID",
    OVERDUE = "OVERDUE",
    CANCELLED = "CANCELLED",
}

export function getBillStatusName(status: BillStatus): string {
    switch (status) {
        case BillStatus.DRAFT:
            return "Entwurf";
        case BillStatus.PAID:
            return "Bezahlt";
        case BillStatus.OVERDUE:
            return "Überfällig";
        case BillStatus.OPEN:
            return "Offen";
        case BillStatus.CANCELLED:
            return "Storniert";
    }
}

export type ManualPartialPayment = {
    id: string;
    /**
     * Format: YYYY-MM-DD
     */
    paymentDate: string;
    paymentAmount: number;
};

export type AutoPartialPayment = ManualPartialPayment & {
    transactionMatchId: string;
    transactionId: string;
    iban: string | null;
    debitorIban: string | null;
    remittanceInformation: string;
};

export const PLACEHOLDER_MANUALLY_BILLED = "__PLACEHOLDER_MANUALLY_BILLED__";

export type BaseStatusUpdateInfo = {
    /**
     * Format: ISO String
     */
    timestamp: string;
};
export type NightlyOverdueStatusUpdateInfo = BaseStatusUpdateInfo & { reason: "NIGHTLY_OVERDUE" };
export type ManualStatusUpdateInfo = BaseStatusUpdateInfo & { reason: "MANUAL" };
export type BankTransactionStatusUpdateInfo = BaseStatusUpdateInfo & {
    reason: "BANK_TRANSACTION";
    matchesSkontoBalance: boolean;
};

export type StatusUpdateInfo =
    | ManualStatusUpdateInfo
    | BankTransactionStatusUpdateInfo
    | NightlyOverdueStatusUpdateInfo;

export class Bill extends Receipt {
    public status: BillStatus;
    public lastStatusUpdateInfo: StatusUpdateInfo | null;
    public paymentDate: string | null;
    public paymentInfos: CustomerPaymentInfos;
    public numberOfBillReminders: number;
    public manualPartialPayments: ManualPartialPayment[];
    public autoPartialPayments: AutoPartialPayment[];
    public printPaymentInfo: boolean;
    public createdFrom: BillCreatedFromInfo | null;
    public photoExportResolution: PhotoSize;
    public cancellation: BillCancellation | null;
    public customerTaxId: string;
    /**
     * total price without cash discount
     */
    public grossPrice: number;
    public isInArchive: boolean;

    public _searchableSubstrings: string[];

    constructor(initialValues?: Partial<Bill>) {
        super(initialValues);
        this.status = initialValues?.status ?? BillStatus.DRAFT;
        this.lastStatusUpdateInfo = initialValues?.lastStatusUpdateInfo ?? null;
        this.paymentDate = initialValues?.paymentDate ?? null;
        this.appendixExports = initialValues?.appendixExports ?? [];
        this.paymentInfos = {
            ...DEFAULT_CUSTOMER_PAYMENT_INFOS,
            ...initialValues?.paymentInfos,
        };
        this.manualPartialPayments = initialValues?.manualPartialPayments ?? [];
        this.autoPartialPayments = initialValues?.autoPartialPayments ?? [];
        this.numberOfBillReminders = initialValues?.numberOfBillReminders ?? 0;
        this.printPaymentInfo = initialValues?.printPaymentInfo ?? true;
        this.createdFrom = initialValues?.createdFrom ?? null;
        this.photoExportResolution = initialValues?.photoExportResolution ?? PhotoSize.L;
        this.cancellation = initialValues?.cancellation ?? null;
        this.customerTaxId = initialValues?.customerTaxId ?? "";
        this.grossPrice = initialValues?.grossPrice ?? 0;
        this.isInArchive = initialValues?.isInArchive ?? false;
        this._searchableSubstrings = initialValues?._searchableSubstrings ?? [];
    }
}

export enum BillCreatedFromInfoType {
    DELIVERY_NOTE = "DELIVERY_NOTE",
    OFFER = "OFFER",
}

export type BillCreatedFromInfo =
    | {
          type: BillCreatedFromInfoType.DELIVERY_NOTE;
          deliveryNoteId: string;
      }
    | {
          type: BillCreatedFromInfoType.OFFER;
          offerId: string;
      };

export type BillCancellation = {
    /**
     * format: YYYY-MM-DD
     */
    date: string;
    latestSentMailInfo: MailTraceMeta | null;
    datevTransfer: DatevTransfer | null;
    printDate: string | null; // ISO datetime
    downloadDate: string | null; // ISO datetime
};

export function generateSearchableSubstringsForBill(bill: Partial<Bill>) {
    const searchableBillAttributes: string[] = [
        bill.receiptNumber,
        bill.billingAddress?.name,
        bill.customerContactPerson,
    ].filter(notNullish);

    if (bill.date) {
        const formattedDate = dayjs(bill.date).format("DD.MM.YYYY");
        searchableBillAttributes.push(formattedDate);
    }

    return generateSearchableSubstrings(searchableBillAttributes);
}
