import dayjs from "dayjs";
import { getTargetHoursForInterval } from "@/components/organization/sharedComponents/TimeTrackingsSummary/utils/getTargetHours";
import { GetEmployeeTimesSummaryParams } from "@/util/employeeUtils";

export function _getEmployeeTargetHours(params: Pick<GetEmployeeTimesSummaryParams, "employee" | "interval">): number {
    const {
        employee,
        interval: [start, end],
    } = params;

    if (!employee) {
        return 0;
    }

    return getTargetHoursForInterval(employee, dayjs(start), dayjs(end));
}
