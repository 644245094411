import { Describe, any, array, boolean, enums, nullable, number, object, record, string } from "superstruct";
import { MachineCounterType } from "./MachineCounterTracking";
import { OperatingUnit } from "./OperatingUnit";
import { IdentifiablePhoto } from "./Order";
import {
    AnyPriceStructure,
    CalculatedServicePriceBlock,
    anyPriceStructureStruct,
    calculatedServicePriceBlockBasisStruct,
} from "./services/ServicePriceBlocks";

export enum MachineType {
    TRACTOR = "TRACTOR",
    SELF_DRIVING = "SELF_DRIVING",
    TRAILER = "TRAILER",
    MOUNT = "MOUNT",
    TRUCK = "TRUCK",
    CONTAINER = "CONTAINER",
    SMALL_DEVICE = "SMALL_DEVICE",
    EXCAVATOR = "EXCAVATOR",
    WHEEL_LOADER = "WHEEL_LOADER",
    OTHER = "OTHER",
}

export function getAllMachineTypes() {
    return [
        MachineType.TRACTOR,
        MachineType.SELF_DRIVING,
        MachineType.TRAILER,
        MachineType.MOUNT,
        MachineType.TRUCK,
        MachineType.CONTAINER,
        MachineType.SMALL_DEVICE,
        MachineType.EXCAVATOR,
        MachineType.WHEEL_LOADER,
        MachineType.OTHER,
    ];
}

export const getMachineTypeName = (type: MachineType) => {
    switch (type) {
        case MachineType.MOUNT:
            return "Anbaugerät";
        case MachineType.SELF_DRIVING:
            return "Selbstfahrer";
        case MachineType.TRACTOR:
            return "Traktor";
        case MachineType.TRAILER:
            return "Anhänger";
        case MachineType.TRUCK:
            return "LKW";
        case MachineType.CONTAINER:
            return "Container";
        case MachineType.SMALL_DEVICE:
            return "Kleingerät";
        case MachineType.EXCAVATOR:
            return "Bagger";
        case MachineType.WHEEL_LOADER:
            return "Radlader";
        case MachineType.OTHER:
            return "Sonstiges";
        default:
            return type;
    }
};

export enum MachinePossessionType {
    LEASING = "Leasing",
    PROPERTY = "Eigentum",
    RENT = "Miete",
    FINANCING = "Finanzierung",
}

export enum MachineUsageUnit {
    NAME = "name",
    VEHICLE_NUMBER = "vehicleNumber",
    TOTAL_HOURS = "totalHours",
    TOTAL_AREA_SQUARE_METERS = "totalAreaSquareMeters",
    TOTAL_TRACKS = "totalTracks",
    TOTAL_TONS = "totalTons",
    TOTAL_CUBIC_METERS = "totalCubicMeters",
    TOTAL_LITER = "totalLiter",
    TOTAL_LOADS = "totalLoads",
    TOTAL_COST = "totalCost",
}

export enum MachineVolumeUnit {
    LITER = "LITER",
    CUBIC = "CUBIC",
    TONS = "TONS",
    KILO = "KILO",
    PIECES = "PIECES",
    METERS = "METERS",
    HECTARES = "HECTARES",
}

export const getMachineVolumeUnitName = (machineVolumeUnit: MachineVolumeUnit) => {
    switch (machineVolumeUnit) {
        case MachineVolumeUnit.LITER:
            return "Liter";
        case MachineVolumeUnit.CUBIC:
            return "Kubikmeter";
        case MachineVolumeUnit.TONS:
            return "Tonnen";
        case MachineVolumeUnit.KILO:
            return "Kilogramm";
        case MachineVolumeUnit.METERS:
            return "Meter";
        case MachineVolumeUnit.PIECES:
            return "Stück";
        case MachineVolumeUnit.HECTARES:
            return "Hektar";
        default:
            return "Unbek. Einheit";
    }
};

export const getMachinePosessionTypeName = (type: MachinePossessionType) => {
    switch (type) {
        case MachinePossessionType.FINANCING:
            return "Finanzierung";
        case MachinePossessionType.LEASING:
            return "Leasing";
        case MachinePossessionType.RENT:
            return "Miete";
        case MachinePossessionType.PROPERTY:
            return "Eigentum";
    }
};

export type CostCalculationAssumptions = {
    purchasePrice: number | null; // Anschaffungspreis
    usageHoursPerYear: number | null;
    usageYears: number | null;
    residualValue: number | null;
    insurancePerYear: number | null;
    taxPerYear: number | null;
    yearlyRate: number | null;
    repairCostYearly: number | null;
    interestYearly: number | null;
};

export const defaultCostCalculationAssumptions: CostCalculationAssumptions = {
    purchasePrice: 0,
    usageHoursPerYear: 0,
    usageYears: 0,
    residualValue: 0,
    insurancePerYear: 0,
    taxPerYear: 0,
    yearlyRate: 0,
    repairCostYearly: 0,
    interestYearly: 0,
};

export type MachineVariantPrice = {
    price: AnyPriceStructure;
    maschinenring: {
        vskz: string | null;
        fuelTarget: boolean;
    };
};
export type MachineVariantPrices = Partial<Record<CalculatedServicePriceBlock["basis"], MachineVariantPrice>>;

export function constructEmptyMachinePrices(): MachineVariantPrices {
    return {};
}

export type RentalPrice = {
    id: string;
    unit: MachineCounterType | string;
    price: AnyPriceStructure;
    maschinenring: {
        fuelTarget: boolean;
        vskz: string | null;
    };
};

export class Machine {
    // general
    id: string;
    internalId: string;
    name: string;
    photo: IdentifiablePhoto | null;
    active: boolean;
    manufacturer: string;
    manufacturingYear: number;
    chassisSerialNumber: string;
    entranceDate: string | null;
    vehicleNumber: string;
    nextInspection: string | null;
    nextSecurityInspection: string | null;
    firstRegistration: string | null;
    owner: string;
    note: string;
    posessionType: MachinePossessionType;
    currentOperatingHours: number;
    counters: MachineCounterType[];

    // technical
    type: MachineType;
    emptyWeightKg: number;
    maxPayloadKg: number;
    volume: number;
    volumeUnit: MachineVolumeUnit;
    workWidthCm: number;

    // cost
    costPerHectar: number;
    costPerUsageHour: number;
    costPerDrivingHour: number;
    costCalculationAssumptions: CostCalculationAssumptions | null;

    // rental
    /**
     * Flag whether this machine is available to rent.
     * Is automatically updated by a Firebase Function if the machine changes.
     * If `rentalPrices` contains some items it is `true`, or `false` if `rentalPrices` is empty.
     * @default false
     */
    readonly isRentable: boolean;
    rentalPrices: RentalPrice[];

    trackerId: string | null;

    archived: boolean;
    operatingUnitIds: Array<OperatingUnit["id"]>;

    variants: MachineVariant[];

    constructor(initialValues?: Partial<Machine>) {
        this.id = initialValues?.id ?? "";
        this.internalId = initialValues?.internalId ?? "";
        this.active = initialValues?.active ?? true;
        this.name = initialValues?.name ?? "";
        this.photo = initialValues?.photo?.imageSrc && initialValues?.photo.storagePath ? initialValues.photo : null;
        this.manufacturer = initialValues?.manufacturer ?? "";
        this.manufacturingYear = initialValues?.manufacturingYear ?? 0;
        this.chassisSerialNumber = initialValues?.chassisSerialNumber ?? "";
        this.entranceDate = initialValues?.entranceDate ?? null;
        this.nextInspection = initialValues?.nextInspection ?? "";
        this.nextSecurityInspection = initialValues?.nextSecurityInspection ?? "";
        this.owner = initialValues?.owner ?? "";
        this.note = initialValues?.note ?? "";
        this.posessionType = initialValues?.posessionType || MachinePossessionType.PROPERTY;
        this.currentOperatingHours = initialValues?.currentOperatingHours ?? 0;
        this.counters = initialValues?.counters ?? [];
        this.firstRegistration = initialValues?.firstRegistration ?? null;
        this.type = initialValues?.type || MachineType.SELF_DRIVING;
        this.emptyWeightKg = initialValues?.emptyWeightKg ?? 0;
        this.volume = initialValues?.volume ?? 0;
        this.volumeUnit = initialValues?.volumeUnit || MachineVolumeUnit.LITER;
        this.workWidthCm = initialValues?.workWidthCm ?? 0;
        this.maxPayloadKg = initialValues?.maxPayloadKg ?? 0;
        this.costPerHectar = initialValues?.costPerHectar ?? 0;
        this.costPerUsageHour = initialValues?.costPerUsageHour ?? 0;
        this.costPerDrivingHour = initialValues?.costPerDrivingHour ?? 0;
        this.vehicleNumber = initialValues?.vehicleNumber ?? "";
        this.costCalculationAssumptions = initialValues?.costCalculationAssumptions ?? null;
        this.isRentable = initialValues?.isRentable ?? (initialValues?.rentalPrices?.length ?? 0) > 0;
        this.rentalPrices = initialValues?.rentalPrices ?? [];
        this.trackerId = initialValues?.trackerId ?? null;
        this.archived = initialValues?.archived ?? false;
        this.operatingUnitIds = initialValues?.operatingUnitIds ?? [];
        this.variants = initialValues?.variants ?? [];
    }
}

export const MACHINE_TYPES_ELIGIBLE_FOR_FUELING = [
    MachineType.TRACTOR,
    MachineType.SELF_DRIVING,
    MachineType.TRUCK,
    MachineType.EXCAVATOR,
    MachineType.WHEEL_LOADER,
    MachineType.OTHER,
];

const machineVariantPricesStruct: Describe<MachineVariant["servicePrices"]> = nullable(
    record(
        calculatedServicePriceBlockBasisStruct,
        object({
            price: anyPriceStructureStruct,
            maschinenring: object({
                vskz: nullable(string()),
                fuelTarget: boolean(),
            }),
        })
    )
) as Describe<MachineVariant["servicePrices"]>; // There is an issue preventing us from describing the model cleanly without casting (see https://github.com/ianstormtaylor/superstruct/issues/1229)

const machineVariantStruct: Describe<MachineVariant> = object({
    id: string(),
    name: string(),
    servicePrices: machineVariantPricesStruct,
});

export const MachineStruct: Describe<Machine> = object({
    id: string(),
    internalId: string(),
    name: string(),
    photo: nullable(object({ storagePath: string(), imageSrc: string() })),
    active: boolean(),
    manufacturer: string(),
    manufacturingYear: number(),
    chassisSerialNumber: string(),
    entranceDate: nullable(string()),
    vehicleNumber: string(),
    nextInspection: nullable(string()),
    nextSecurityInspection: nullable(string()),
    firstRegistration: nullable(string()),
    owner: string(),
    note: string(),
    posessionType: enums(Object.values(MachinePossessionType)),
    currentOperatingHours: number(),
    counters: array(enums(Object.values(MachineCounterType))),
    type: enums(Object.values(MachineType)),
    emptyWeightKg: number(),
    maxPayloadKg: number(),
    volume: number(),
    volumeUnit: enums(Object.values(MachineVolumeUnit)),
    workWidthCm: number(),
    costPerHectar: number(),
    costPerUsageHour: number(),
    costPerDrivingHour: number(),
    costCalculationAssumptions: nullable(
        object({
            purchasePrice: nullable(number()),
            usageHoursPerYear: nullable(number()),
            usageYears: nullable(number()),
            residualValue: nullable(number()),
            insurancePerYear: nullable(number()),
            taxPerYear: nullable(number()),
            yearlyRate: nullable(number()),
            repairCostYearly: nullable(number()),
            interestYearly: nullable(number()),
        })
    ),
    isRentable: boolean(),
    rentalPrices: array(any()),
    archived: boolean(),
    operatingUnitIds: array(string()),
    trackerId: nullable(string()),
    variants: array(machineVariantStruct),
});

export type MachineVariantIdentifier = {
    machineId: string;
    variantId: string;
};

export type MachineVariant = {
    id: string;
    name: string;
    servicePrices: MachineVariantPrices | null;
};
