import { Employee } from "@farmact/model/src/model/Employee";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { TimeTrackingsView } from "@/components/inputs/TimeTrackingsViewSelect/TimeTrackingsView";

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

export function getTargetHours(
    employee: Employee | null,
    view: TimeTrackingsView,
    startDate: Dayjs,
    endDate: Dayjs
): number | null {
    if (!employee) {
        return null;
    }
    if (employee.targetWorkHoursWeekly) {
        return getTargetHoursForInterval(employee, startDate, endDate);
    }
    if (view === TimeTrackingsView.MONTH) {
        return getTargetHoursForMonth(employee, startDate);
    } else if (view === TimeTrackingsView.YEAR) {
        let sum = 0;
        let month = startDate.startOf("year");
        for (let i = 0; i < 12; i++, month = month.add(1, "month")) {
            sum += getTargetHoursForMonth(employee, month);
        }
        return sum;
    } else {
        return null;
    }
}

export function getTargetHoursForInterval(employee: Employee, startDate: Dayjs, endDate: Dayjs) {
    if (!employee.targetWorkHoursWeekly) {
        return 0;
    }

    if (!employee.hiringDate) {
        const workDays = getWorkDaysBetweenTwoDates(startDate.toDate(), endDate.toDate());
        return (employee.targetWorkHoursWeekly / 5) * workDays;
    }
    const hiringDate = dayjs(employee.hiringDate).startOf("day");
    if (hiringDate.isAfter(endDate)) {
        return 0;
    }
    const relevantStartDate = hiringDate.isBetween(startDate, endDate) ? hiringDate.toDate() : startDate.toDate();
    const workDays = getWorkDaysBetweenTwoDates(relevantStartDate, endDate.toDate());
    return (employee.targetWorkHoursWeekly / 5) * workDays;
}

function getTargetHoursForMonth(employee: Employee, dateOfMonth: Dayjs) {
    if (!employee.hiringDate) {
        return employee.targetHoursMonthly;
    }
    const startOfMonth = dateOfMonth.startOf("month");
    const hiringDate = dayjs(employee.hiringDate).startOf("day");

    if (!hiringDate.isAfter(startOfMonth)) {
        return employee.targetHoursMonthly;
    }
    if (hiringDate.isAfter(startOfMonth.endOf("month"))) {
        return 0;
    }

    const nextMonth = startOfMonth.add(1, "month");
    const daysInMonth = dateOfMonth.endOf("month").date();
    return (nextMonth.diff(hiringDate, "days") / daysInMonth) * employee.targetHoursMonthly;
}

export function getWorkDaysBetweenTwoDates(startDate: Date, endDate: Date): number {
    let workDays = 0;
    const current = new Date(startDate);
    while (dayjs(current).isSameOrBefore(endDate, "day")) {
        if (current.getDay() !== 0 && current.getDay() !== 6) {
            workDays++;
        }
        current.setDate(current.getDate() + 1);
    }
    return workDays;
}
