import {
    EmployeeTotalOvertimeResult,
    isEmployeeTotalOvertimeError,
} from "@/util/customHooks/employees/useEmployeeTotalOvertime";
import { GetEmployeeTimesSummaryParams } from "@/util/employeeUtils";

export function _getEmployeeTotalOvertimeMinutes(
    params: GetEmployeeTimesSummaryParams
): EmployeeTotalOvertimeResult | undefined {
    const { totalOvertimeHours } = params;

    if (totalOvertimeHours === undefined) {
        return;
    }
    if (isEmployeeTotalOvertimeError(totalOvertimeHours)) {
        return totalOvertimeHours;
    }
    return totalOvertimeHours * 60;
}
