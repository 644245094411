import { Machine, MachineVariantIdentifier } from "@farmact/model/src/model/Machine";
import clsx from "clsx";
import * as FormControl from "@/components/inputs/FormControl";
import { SingleMachineSelect } from "@/components/inputs/machineInputs/SingleMachineSelect";
import { SingleSelect } from "@/components/inputs/SingleSelect/SingleSelect";
import { DeleteIconButton } from "@/components/snippets/DeleteIconButton/DeleteIconButton";
import { SingleValidationError } from "@/util/customHooks/validation/useValidation";
import "./machineVariantItem.scss";

type MachineVariantItemProps = {
    value: MachineVariantIdentifier;
    availableMachines: Machine[];
    onChange: (newMachineVariant: MachineVariantIdentifier) => void;
    onDelete: () => void;
    machineFixed?: boolean;
    error?: SingleValidationError;
};

export function MachineVariantItem(props: MachineVariantItemProps) {
    const machine = props.availableMachines.find(machine => machine.id === props.value.machineId);
    if (!machine) {
        return null;
    }

    const handleMachineChange = (newMachine: Machine | null) => {
        if (!newMachine) {
            return;
        }
        if (newMachine.variants.length === 1) {
            props.onChange({ machineId: newMachine.id, variantId: newMachine.variants[0].id });
        } else {
            props.onChange({ machineId: newMachine.id, variantId: "" });
        }
    };

    const handleVariantChange = (newVariant: MachineVariantIdentifier["variantId"] | null) => {
        props.onChange({ machineId: props.value.machineId, variantId: newVariant ?? "" });
    };

    const machineHasVariants = machine.variants.length > 1;
    return (
        <div className="machine-variant-item">
            <FormControl.Root>
                <FormControl.Label>
                    <span className="machine-variant-item__machine-label">Maschine</span>
                </FormControl.Label>
                {props.machineFixed && <div className="machine-variant-item__machine-select">{machine.name}</div>}
                {!props.machineFixed && (
                    <SingleMachineSelect
                        className="machine-variant-item__machine-select"
                        value={machine.id}
                        data={props.availableMachines}
                        onModelChange={handleMachineChange}
                        clearable={false}
                    />
                )}
            </FormControl.Root>
            {machineHasVariants && (
                <FormControl.Root>
                    <FormControl.Label>
                        <span
                            className={clsx(
                                "machine-variant-item__variant-label",
                                props.error && "machine-variant-item__variant-label--error"
                            )}>
                            Variante
                        </span>
                    </FormControl.Label>
                    <SingleSelect
                        className="machine-variant-item__variant-select"
                        value={props.value.variantId}
                        data={machine.variants.map(variant => ({ value: variant.id, label: variant.name }))}
                        onChange={handleVariantChange}
                        clearable={false}
                        error={props.error}
                    />
                </FormControl.Root>
            )}
            <DeleteIconButton
                onDelete={props.onDelete}
                className="machine-variant-item__delete-icon"
                disabled={props.machineFixed}
            />
        </div>
    );
}
