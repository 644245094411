import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { ReactNode } from "react";
import { FaAlertContent } from "./FaAlertContent";
import { AlertAction } from "./FaAlertContext";
import "./faAlert.scss";

export type FaAlertProps = {
    isOpen: boolean;
    onDismiss: () => void;
    /** @default true */
    backdropDismiss?: boolean;

    title?: string;
    message: ReactNode;

    actions?: AlertAction[];

    className?: string;
};

// TODO: FACT-8979 - look through the usages and avoid closing modals when showing alerts

export function FaAlert(props: FaAlertProps) {
    const handleOpenChange = (open: boolean) => {
        if (!open) {
            props.onDismiss?.();
        }
    };

    return (
        <AlertDialog.Root open={props.isOpen} onOpenChange={handleOpenChange}>
            <AlertDialog.Portal>
                <FaAlertContent {...props} />
            </AlertDialog.Portal>
        </AlertDialog.Root>
    );
}
