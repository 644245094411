import { Describe, array, assign, boolean, enums, literal, nullable, object, string, union } from "superstruct";
import { AnyDriverQuery } from "../Order";
import { ResourceTag } from "../ResourceTag";

export enum DriverQueryInputTime {
    START = "START",
    STOP = "STOP",
    ANY = "ANY",
}

export enum DriverQueryType {
    YES_NO = "YES_NO",
    VALUE = "VALUE",
    BEFORE_AFTER = "BEFORE_AFTER",
    RESOURCE_WITH_AMOUNT = "RESOURCE_WITH_AMOUNT",
    RESOURCE_ONLY = "RESOURCE_ONLY",
}

export function getDriverQueryName(driverQuery: AnyDriverQuery): string {
    if (driverQuery.name) {
        return driverQuery.name;
    }

    return getDriverQueryTypeName(driverQuery.type);
}

export function getDriverQueryTypeName(driverQueryType: DriverQueryType): string {
    switch (driverQueryType) {
        case DriverQueryType.YES_NO:
            return "Ja / Nein";
        case DriverQueryType.VALUE:
            return "Wert";
        case DriverQueryType.BEFORE_AFTER:
            return "Vorher / Nachher";
        case DriverQueryType.RESOURCE_WITH_AMOUNT:
            return "Artikel";
        case DriverQueryType.RESOURCE_ONLY:
            return "Artikel (ohne Anzahl)";
    }
}

export type DriverQueryTemplate = {
    id: string;
    name: string;
    info: string | null;
    required: boolean;
};

export type DriverQuerySingleValueTemplate = DriverQueryTemplate & {
    type: DriverQueryType.VALUE;
    unit: string;
    when: DriverQueryInputTime;
};

export type DriverQueryYesNoTemplate = DriverQueryTemplate & {
    type: DriverQueryType.YES_NO;
    when: DriverQueryInputTime;
};

export type DriverQueryBeforeAfterTemplate = DriverQueryTemplate & {
    type: DriverQueryType.BEFORE_AFTER;
    unit: string;
};

export type DriverQueryResourceWithAmountTemplate = DriverQueryTemplate & {
    type: DriverQueryType.RESOURCE_WITH_AMOUNT;
    restriction: DriverQueryResourceRestriction | null;
    when: DriverQueryInputTime;
};

export type DriverQueryResourceOnlyTemplate = DriverQueryTemplate & {
    type: DriverQueryType.RESOURCE_ONLY;
    restriction: DriverQueryResourceRestriction | null;
    when: DriverQueryInputTime;
};

export type AnyDriverQueryTemplate =
    | DriverQuerySingleValueTemplate
    | DriverQueryYesNoTemplate
    | DriverQueryBeforeAfterTemplate
    | DriverQueryResourceWithAmountTemplate
    | DriverQueryResourceOnlyTemplate;

export enum ResourceRestrictionType {
    NONE = "NONE",
    CATEGORY = "CATEGORY",
    RESOURCE = "RESOURCE",
}

export type DriverQueryResourceRestrictionCategory = {
    type: ResourceRestrictionType.CATEGORY;
    tags: ResourceTag["id"][];
};

export type DriverQueryResourceRestrictionResource = {
    type: ResourceRestrictionType.RESOURCE;
    resourceId: string;
    resourceVariantId: string | null;
};

export type DriverQueryResourceRestriction =
    | DriverQueryResourceRestrictionCategory
    | DriverQueryResourceRestrictionResource;

const DriverQueryTemplateStruct: Describe<DriverQueryTemplate> = object({
    id: string(),
    name: string(),
    info: nullable(string()),
    required: boolean(),
});

export const DriverQuerySingleValueTemplateStruct: Describe<DriverQuerySingleValueTemplate> = assign(
    DriverQueryTemplateStruct,
    object({
        type: literal(DriverQueryType.VALUE),
        unit: string(),
        when: enums(Object.values(DriverQueryInputTime)),
    })
);

export const DriverQueryYesNoTemplateStruct: Describe<DriverQueryYesNoTemplate> = assign(
    DriverQueryTemplateStruct,
    object({
        type: literal(DriverQueryType.YES_NO),
        when: enums(Object.values(DriverQueryInputTime)),
    })
);

export const DriverQueryBeforeAfterTemplateStruct: Describe<DriverQueryBeforeAfterTemplate> = assign(
    DriverQueryTemplateStruct,
    object({
        type: literal(DriverQueryType.BEFORE_AFTER),
        unit: string(),
    })
);

type DriverQueryResourceOmitType = Omit<DriverQueryResourceWithAmountTemplate, "type">;

export const DriverQueryResourceOmitTypeStruct: Describe<DriverQueryResourceOmitType> = assign(
    DriverQueryTemplateStruct,
    object({
        restriction: nullable(
            union([
                object({
                    type: literal(ResourceRestrictionType.CATEGORY),
                    tags: array(string()),
                }),
                object({
                    type: literal(ResourceRestrictionType.RESOURCE),
                    resourceId: string(),
                    resourceVariantId: nullable(string()),
                }),
            ])
        ),
        when: enums(Object.values(DriverQueryInputTime)),
    })
);

export const DriverQueryResourceWithAmountTemplateStruct: Describe<DriverQueryResourceWithAmountTemplate> = assign(
    DriverQueryResourceOmitTypeStruct,
    object({
        type: literal(DriverQueryType.RESOURCE_WITH_AMOUNT),
    })
);

export const DriverQueryResourceOnlyTemplateStruct: Describe<DriverQueryResourceOnlyTemplate> = assign(
    DriverQueryResourceOmitTypeStruct,
    object({
        type: literal(DriverQueryType.RESOURCE_ONLY),
    })
);
