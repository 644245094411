import { Firebase } from "@/firebase";
import { useMergedQueryData } from "@/firebase/utils/useMergedQueryData/useMergedQueryData";
import { useStableIdArray } from "@/util/customHooks/useStableArray";
import { Customer } from "@farmact/model/src/model/Customer";
import { Order } from "@farmact/model/src/model/Order";
import { RentalOrder, isRentalOrder } from "@farmact/model/src/model/RentalOrder";
import { Query, query, where } from "firebase/firestore";

type UseOrderCustomersReturn = {
    customers: Customer[];
    loading: boolean;
};

export function useOrderCustomers(order: Order | RentalOrder | undefined): UseOrderCustomersReturn {
    const customerIds = useStableIdArray(getCustomerIds(order));

    const [customers, loading] = useMergedQueryData(customerIds, customersQueryGenerator);

    return {
        customers,
        loading,
    };
}

function getCustomerIds(order: Order | RentalOrder | undefined): Array<Customer["id"]> {
    if (order === undefined) {
        return [];
    }

    if (isRentalOrder(order)) {
        return [order.customerId];
    }

    return order.customerIds;
}

function customersQueryGenerator(customerIds: Array<Customer["id"]>): Query<Customer> {
    return query(Firebase.instance().getAllCustomers(), where("id", "in", customerIds));
}
