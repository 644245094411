import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { FaItemDivider } from "@/components/FaItem/FaItemDivider/FaItemDivider";
import "./faItemGroup.scss";

type FaItemGroupProps = ComponentPropsWithoutRef<"div"> & { title?: string };

export function FaItemGroup(props: FaItemGroupProps) {
    const { children, title, ...restProps } = props;
    return (
        <div {...restProps} className={clsx("fa-item-group", props.className)}>
            {title && <FaItemDivider title={title} />}
            {children}
        </div>
    );
}
