import { Absence } from "@farmact/model/src/model/Absence";
import { Activity } from "@farmact/model/src/model/Employee";
import { Overtime } from "@farmact/model/src/model/Overtime";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import { createContext } from "react";

export type DayGroupedWorkTimes = {
    day: Date;
    timeTrackings: TimeTracking[];
    overtimes: Overtime[];
    absences: Absence[];
    currentActivity: Activity | null;
};

type TableGroupContext = {
    open: boolean;
    setOpen: (open: boolean) => void;
    group: DayGroupedWorkTimes;
};
export const TableGroupContext = createContext<TableGroupContext>({
    open: false,
    setOpen: () => {},
    group: { day: new Date(), timeTrackings: [], overtimes: [], absences: [], currentActivity: null },
});
