import { immerable } from "immer";
import { Describe, array, boolean, enums, nullable, number, object, string } from "superstruct";
import { v4 } from "uuid";
import { OperatingUnit } from "./OperatingUnit";
import {
    ResourcePrice,
    ResourcePriceCalculationType,
    ResourcePriceStruct,
    ResourcePriceVariantsType,
} from "./ResourcePrice";
import { ResourceTag } from "./ResourceTag";
import { PriceStructureType } from "./services/ServicePriceBlocks";

export enum ResourceUnit {
    LITER = "LITER",
    MILLILITER = "MILLILITER",
    CUBIC_METER = "CUBIC_METER",
    SPACE_CUBIC_METER = "SPACE_CUBIC_METER",
    BULK_CUBIC_METER = "BULK_CUBIC_METER",
    KILOGRAM = "KILOGRAM",
    GRAM = "GRAM",
    KILOMETER = "KILOMETER",
    TON = "TON",
    METER = "METER",
    PIECE = "PIECE",
    HOUR = "HOUR",
    DAY = "DAY",
    CARTRIDGE = "CARTRIDGE",
    SEED = "SEED",
    HECTARE = "HECTARE",
    SQUARE_METER = "SQUARE_METER",
    QUINTAL = "QUINTAL",
    KILO_WATT_HOUR = "KILO_WATT_HOUR",
    UNIT = "UNIT",
    FLATRATE = "FLATRATE",
    MEGA_WATT_HOUR = "MEGA_WATT_HOUR",
    FEE = "FEE",
    LEGACY_LITER = "Liter",
    LEGACY_KILOGRAM = "Kilogramm",
    LEGACY_PIECE = "Stück",
    LEGACY_CARTRIDGE = "Kartuschen",
}

export class Resource {
    [immerable] = true;
    public id: string;
    public name: string;
    public unit: ResourceUnit;
    public tags: ResourceTag["id"][];
    public other: string;
    public archived: boolean;
    public vatPercentPoints: number | null;
    public billDescription: string | null;
    public inventoryEnabled: boolean;
    public inventoryCount: number;
    public accountNumber: number | null;
    public price: ResourcePrice<ResourcePriceCalculationType>;
    public operatingUnitIds: Array<OperatingUnit["id"]>;

    constructor(initialValues?: Partial<Resource>) {
        this.id = initialValues?.id ?? "";
        this.name = initialValues?.name ?? "";
        this.unit = initialValues?.unit ?? ResourceUnit.PIECE;
        this.other = initialValues?.other ?? "";
        this.archived = initialValues?.archived ?? false;
        this.price = initialValues?.price ?? {
            calculationType: ResourcePriceCalculationType.FIXED,
            variantsType: ResourcePriceVariantsType.SIMPLE,
            pricePerUnit: {
                price: 0,
                type: PriceStructureType.SIMPLE,
            },
            costPerUnit: 0,
            id: v4(),
            name: "Standardpreis",
            vskz_mr: null,
        };
        this.vatPercentPoints = initialValues?.vatPercentPoints ?? null;
        this.billDescription = initialValues?.billDescription ?? null;
        this.inventoryEnabled = initialValues?.inventoryEnabled ?? false;
        this.inventoryCount = initialValues?.inventoryCount ?? 0;
        this.accountNumber = initialValues?.accountNumber ?? null;
        this.operatingUnitIds = initialValues?.operatingUnitIds ?? [];
        this.tags = initialValues?.tags ?? [];
    }
}

export const ResourceStruct: Describe<Omit<Resource, typeof immerable>> = object({
    id: string(),
    name: string(),
    unit: enums(Object.values(ResourceUnit)),
    tags: array(string()),
    other: string(),
    archived: boolean(),
    vatPercentPoints: nullable(number()),
    billDescription: nullable(string()),
    inventoryEnabled: boolean(),
    inventoryCount: number(),
    accountNumber: nullable(number()),
    price: ResourcePriceStruct,
    operatingUnitIds: array(string()),
});
