import { Employee } from "@farmact/model/src/model/Employee";
import { Overtime } from "@farmact/model/src/model/Overtime";
import dayjs from "dayjs";
import { Query } from "firebase/firestore";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import { useStableDateRange } from "@/util/customHooks/useStableDateRange";
import { ModelOrId, getModelId } from "@/util/select";
import { DateRange } from "@/util/timeUtils";

type UseEmployeeOvertimesParams = {
    employee: ModelOrId<Employee> | undefined;
    interval: DateRange;
};

type UseEmployeeOvertimesReturn = {
    loading: boolean;
    overtimes: Overtime[];
};

export function useEmployeeOvertimesInInterval(params: UseEmployeeOvertimesParams): UseEmployeeOvertimesReturn {
    const interval = useStableDateRange(params.interval);

    const employeeId = params.employee ? getModelId(params.employee) : undefined;

    const [overtimes, loading] = useCollectionData(
        getQuery({
            employeeId,
            interval,
        }),
        [employeeId, interval]
    );

    return {
        loading,
        overtimes,
    };
}

type GetQueryParams = {
    employeeId: string | undefined;
    interval: DateRange;
};

function getQuery(params: GetQueryParams): Query<Overtime> | undefined {
    if (!params.employeeId) {
        return;
    }

    const [start, end] = params.interval;

    return Firebase.instance().getOvertimeForEmployeeInDateRange(
        params.employeeId,
        dayjs(start).startOf("day"),
        dayjs(end).endOf("day")
    );
}
