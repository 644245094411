export enum ServicePriceUnit {
    HOUR = "Stunden",
    HECTARE = "Hektar",
    SQUAREMETER = "Quadratmeter",
    FLAT = "Pauschale",
    METER = "Meter",
    KILOMETER = "Kilometer",
    TONS = "TONS",
    CUBIC_METERS = "CUBIC_METERS",
    LITER = "LITER",
    LOADS = "SERVICE_PRICE_UNIT_LOADS",
}
