import { Activity } from "@farmact/model/src/model/Employee";
import clsx from "clsx";
import dayjs from "dayjs";
import { ComponentPropsWithoutRef } from "react";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { WorkTimeTag } from "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/shared/WorkTimeTag/WorkTimeTag";
import { useTableGroupContext } from "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/useTableGroupContext";
import { Pulse } from "@/components/snippets/Pulse/Pulse";
import { formatCurrentActivityName } from "@/util/employeeUtils";
import "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/shared/tableCells.scss";

type TableCellsProps = ComponentPropsWithoutRef<"div"> & {
    activity: Activity;
};

export const CurrentActivityTableCells = (props: TableCellsProps) => {
    const { activity, ...restProps } = props;

    const { appCompany } = useAppCompanyContext();
    const { group } = useTableGroupContext();

    return (
        <>
            <div {...restProps} className={clsx("table-cells", props.className)}>
                <div className="table-cells__cell table-cells__work-time">
                    <WorkTimeTag>
                        {dayjs(group.day).isAfter(activity.startDateTime)
                            ? "00:00"
                            : formatTime(dayjs(activity.startDateTime).toDate())}
                    </WorkTimeTag>
                    <span>-</span>
                    <WorkTimeTag>offen</WorkTimeTag>
                </div>
                <div className="table-cells__cell table-cells__break" />
                <div className="table-cells__cell table-cells__total" />
                <div className="table-cells__cell table-cells__activity">
                    {formatCurrentActivityName(activity, appCompany) ?? "Unbekannte Aktivität"}
                </div>
                <div className="table-cells__cell table-cells__note">{activity.note}</div>
                <div className="table-cells__cell">
                    <Pulse color="primary" tooltip="Diese Zeiterfassung ist gerade aktiv" />
                </div>
            </div>
        </>
    );
};

function formatTime(date: Date): string {
    return dayjs(date).format("HH:mm");
}
