import { Machine } from "@farmact/model/src/model/Machine";
import { SingleSelect } from "@/components/inputs/SingleSelect/SingleSelect";
import { useOrganizationContext } from "@/components/organization/context/useOrganizationContext";
import { useFilterEmployeeHasAccess } from "@/util/customHooks/useFilterEmployeeHasAccess";
import { SingleSelectProps, getModelId, getSingleSelectChangeHandler, useSelectOptions } from "@/util/select";
import { createMachineSelectItem, defaultSelectableMachinesSorter } from "./machineInputsUtils";

type SingleMachineSelectProps = SingleSelectProps<Machine>;

export const SingleMachineSelect = (props: SingleMachineSelectProps) => {
    const {
        placeholder = "Maschine auswählen",
        data: controlledMachines,
        optionCreator = createMachineSelectItem,
        sorter = defaultSelectableMachinesSorter,
        value,
        onValueChange,
        onModelChange,
        ...restProps
    } = props;

    const { machines: uncontrolledMachines } = useOrganizationContext();

    const machines = controlledMachines || uncontrolledMachines;

    const selectableMachines = useFilterEmployeeHasAccess(machines, machine => {
        if (value && getModelId(value) === machine.id) {
            return true;
        }
        return machine.operatingUnitIds;
    });

    const items = useSelectOptions(selectableMachines, {
        optionCreator,
        sorter,
    });

    return (
        <SingleSelect
            {...restProps}
            placeholder={placeholder}
            searchPlaceholder="Maschinen durchsuchen"
            data={items}
            value={value ? getModelId(value) : null}
            onChange={getSingleSelectChangeHandler(selectableMachines, {
                onValueChange,
                onModelChange,
            })}
        />
    );
};
