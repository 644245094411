import clsx from "clsx";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { ComponentPropsWithoutRef, useRef } from "react";
import trashLottie from "./trash.json";
import "./deleteIconButton.scss";

type DeleteIconButtonOwnProps = {
    onDelete: () => void;
};

type DeleteIconButtonProps = DeleteIconButtonOwnProps & ComponentPropsWithoutRef<"button">;

export const DeleteIconButton = (props: DeleteIconButtonProps) => {
    const { onDelete, ...restProps } = props;

    const refClick = useRef<LottieRefCurrentProps>(null);

    const handleClick = () => {
        if (restProps.disabled) {
            return;
        }
        refClick.current?.play();
    };

    const handleLottieEnd = () => {
        setTimeout(() => {
            onDelete();
        }, 100);
    };

    return (
        <button
            {...restProps}
            className={clsx("delete-icon-button", restProps.className)}
            onClick={handleClick}
            type="button">
            <Lottie
                lottieRef={refClick}
                animationData={trashLottie}
                loop={false}
                autoplay={false}
                onComplete={handleLottieEnd}
                className="delete-icon-button__lottie"
            />
        </button>
    );
};
