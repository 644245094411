import { useEffect, useState } from "react";
import { v4 } from "uuid";

/**
 * Creates an unique HTMLElement which can be used for `ReactDOM.createPortal(...)`.
 * This hook makes sure that only one element is created and discards it once the implementing component unmounts.
 * @returns An HTMLElement
 */
export function usePortal() {
    const [portal] = useState(() => {
        const portal = document.createElement("div");
        portal.id = `portal-${v4()}`;
        document.body.appendChild(portal);
        return portal;
    });

    useEffect(() => {
        return () => {
            if (document.body.contains(portal)) {
                document.body.removeChild(portal);
            }
        };
    }, [portal]);

    return portal;
}
