export enum BillingMode {
    PER_DAY = "PER_DAY",
    PER_MONTH = "PER_MONTH",
    NEW_EMPLOYEE = "NEW_EMPLOYEE",
}

export const getBillingModeName = (mode: BillingMode) => {
    if (mode === BillingMode.PER_DAY) {
        return "Pro Tag";
    }
    if (mode === BillingMode.PER_MONTH) {
        return "Pro Monat";
    }
    if (mode === BillingMode.NEW_EMPLOYEE) {
        return "Anfangsmonat";
    }
    return "";
};
