import { Machine } from "@farmact/model/src/model/Machine";
import { Order } from "@farmact/model/src/model/Order";
import { useOrganizationContext } from "@/components/organization/context/useOrganizationContext";
import { computed } from "@/util/functions";

export function useOrderMachines(order: Order | undefined) {
    const { machines } = useOrganizationContext();
    return computed(() => {
        if (!order) {
            return [];
        }
        const availableMachines: Machine[] = [...order.machineSnapshots];
        for (const machineId of order.machineIds) {
            if (availableMachines.find(machineSnapshot => machineSnapshot.id === machineId)) {
                continue;
            }
            const machine = machines.find(machine => machine.id === machineId);
            if (machine) {
                availableMachines.push(machine);
            }
        }
        return availableMachines;
    });
}
