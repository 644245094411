import { ActivityPresetPurpose, AppCompany } from "@farmact/model/src/model/AppCompany";
import { Employee } from "@farmact/model/src/model/Employee";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import dayjs, { Dayjs } from "dayjs";
import { Query, QueryFilterConstraint, and, getAggregateFromServer, or, query, sum, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { useEmployeeStartDate } from "@/components/organization/employees/utils/useEmployeeStartDate";
import { getWorkDaysBetweenTwoDates } from "@/components/organization/sharedComponents/TimeTrackingsSummary/utils/getTargetHours";
import { Firebase } from "@/firebase";
import { useStableDateRange } from "@/util/customHooks/useStableDateRange";
import { _getEmployeeAbsenceMinutes } from "@/util/employees/_getEmployeeAbsenceMinutes";
import { recordError } from "@/util/recordError";
import { useAllEmployeeAbsences } from "./useEmployeeAbsences";
import { useEmployeeOvertimesInInterval } from "./useEmployeeOvertimesInInterval";

type UseEmployeeTotalOvertimeParams = {
    employee: Employee | undefined;
};

type UseEmployeeTotalOvertimeReturn = {
    loading: boolean;
    overtimeHours: EmployeeTotalOvertimeResult | undefined;
    reload: () => void;
};

export function useEmployeeTotalOvertime(params: UseEmployeeTotalOvertimeParams): UseEmployeeTotalOvertimeReturn {
    const { employee } = params;

    const employeeId = employee?.id;

    const { appCompany } = useAppCompanyContext();

    const [counter, setCounter] = useState(0);
    const [totalHoursFromTimeTrackingsLoading, setTotalHoursFromTimeTrackingsLoading] = useState(!!employee);
    const [totalHoursFromTimeTrackings, setTotalHoursFromTimeTrackings] = useState<
        number | EmployeeTotalOvertimeError
    >();

    const [startDate, startDateLoading] = useEmployeeStartDate(employeeId);
    const interval = useStableDateRange([
        startDate?.toDate() ?? dayjs().startOf("date").toDate(),
        dayjs().endOf("date").toDate(),
    ]);
    const { overtimes, loading: overtimesLoading } = useEmployeeOvertimesInInterval({
        employee,
        interval,
    });
    const [absences, absencesLoading] = useAllEmployeeAbsences(employee?.id);
    const totalAbsenceMinutes = startDate
        ? _getEmployeeAbsenceMinutes({
              employee,
              interval,
              absences,
          })
        : 0;

    const startDateString = startDate?.startOf("date").toISOString();
    useEffect(() => {
        if (!employeeId || !startDateString) {
            return;
        }

        setTotalHoursFromTimeTrackingsLoading(true);

        const totalQuery = getTotalWorkDurationQuery(employeeId, startDateString);
        const pauseQuery = getTotalPauseDurationQuery(employeeId, startDateString, appCompany);

        const load = async (query: Query<TimeTracking>): Promise<number> => {
            const snapshot = await getAggregateFromServer(query, {
                totalDurationHours: sum("durationHours"),
            });
            return snapshot.data().totalDurationHours;
        };

        (async () => {
            try {
                const [totalHours, pauseHours] = await Promise.all([load(totalQuery), load(pauseQuery)]);
                setTotalHoursFromTimeTrackings(totalHours - pauseHours);
            } catch (error) {
                setTotalHoursFromTimeTrackings(EmployeeTotalOvertimeError.LOADING_FAILED);
                recordError("failed to load employee overtime", {
                    error,
                    employeeId,
                });
            } finally {
                setTotalHoursFromTimeTrackingsLoading(false);
            }
        })();
    }, [appCompany, counter, employeeId, startDateString]);

    const overtimeHours = _getTotalOvertimeHoursFromTimeTrackings({
        employee,
        totalHours: totalHoursFromTimeTrackings,
        startDate,
    });
    const manualOvertimeHours = overtimes.reduce((acc, curr) => acc + curr.amount, 0);

    const loading = totalHoursFromTimeTrackingsLoading || startDateLoading || absencesLoading || overtimesLoading;

    const overtimeResult =
        typeof overtimeHours === "number"
            ? overtimeHours + manualOvertimeHours + totalAbsenceMinutes / 60
            : overtimeHours;

    return {
        loading,
        overtimeHours: loading ? EmployeeTotalOvertimeError.STILL_LOADING : overtimeResult,
        reload: () => {
            setCounter(prev => prev + 1);
        },
    };
}

function getTotalWorkDurationQuery(employeeId: string, from: TimeTracking["startDateTime"]): Query<TimeTracking> {
    return query(
        Firebase.instance().getAllTimeTrackings(),
        where("employeeId", "==", employeeId),
        where("startDateTime", ">=", from)
    );
}

function getTotalPauseDurationQuery(
    employeeId: string,
    from: TimeTracking["startDateTime"],
    appCompany: AppCompany | undefined
): Query<TimeTracking> {
    const workTypeConstraints: QueryFilterConstraint[] = [];

    const orderPausePreset = appCompany?.settings.orderActivityPresets.find(preset => {
        return preset.purpose === ActivityPresetPurpose.PAUSE;
    });
    if (orderPausePreset) {
        workTypeConstraints.push(where("orderActivityPresetId", "==", orderPausePreset.id));
    }

    const internalPausePreset = appCompany?.settings.internalActivityPresets.find(preset => {
        return preset.purpose === ActivityPresetPurpose.PAUSE;
    });
    if (internalPausePreset) {
        workTypeConstraints.push(where("internalActivityPresetId ", "==", internalPausePreset.id));
    }

    const constraints: QueryFilterConstraint[] = [
        where("employeeId", "==", employeeId),
        where("startDateTime", ">=", from),
    ];
    if (workTypeConstraints.length > 0) {
        constraints.push(or(...workTypeConstraints));
    }

    return query(Firebase.instance().getAllTimeTrackings(), and(...constraints));
}

export enum EmployeeTotalOvertimeError {
    STILL_LOADING = "STILL_LOADING",
    LOADING_FAILED = "LOADING_FAILED",
    MISSING_TARGET_HOURS_WEEKLY = "MISSING_TARGET_HOURS_WEEKLY",
    MISSING_START_DATE = "MISSING_START_DATE",
}

export function isEmployeeTotalOvertimeError(value: unknown): value is EmployeeTotalOvertimeError {
    return typeof value === "string" && Object.keys(EmployeeTotalOvertimeError).includes(value);
}

export type EmployeeTotalOvertimeResult = number | EmployeeTotalOvertimeError;

export type GetTotalOvertimeHoursFromTimeTrackingsParams = {
    employee: Employee | undefined;
    totalHours: number | EmployeeTotalOvertimeError | undefined;
    startDate: Dayjs | undefined;
};

export function _getTotalOvertimeHoursFromTimeTrackings(
    params: GetTotalOvertimeHoursFromTimeTrackingsParams
): EmployeeTotalOvertimeResult | undefined {
    const { employee, startDate, totalHours } = params;

    if (totalHours === undefined) {
        return;
    }
    if (isEmployeeTotalOvertimeError(totalHours)) {
        return totalHours;
    }
    if (!employee || employee.targetWorkHoursWeekly === null) {
        return EmployeeTotalOvertimeError.MISSING_TARGET_HOURS_WEEKLY;
    }

    if (!startDate) {
        return EmployeeTotalOvertimeError.MISSING_START_DATE;
    }

    const workedDays = getWorkDaysBetweenTwoDates(
        dayjs(startDate).startOf("day").toDate(),
        dayjs().endOf("day").toDate()
    );
    const workedWeeks = workedDays / 5;
    const targetWorkedHours = workedWeeks * employee.targetWorkHoursWeekly;

    return totalHours - targetWorkedHours;
}
