import { useMemo } from "react";

/**
 * This hook returns a new array whenever the ids of the elements of the input array change.
 * This can, for example, be helpful if you want to include an array in the dependency list of
 * another hook, but only want to cause a rerender if the content of the array changes
 * (not only the reference).
 * @param array
 */
export function useStableArrayById<T extends { id: string }>(array: T[]) {
    const dataHash = array
        .map(element => element.id)
        .sort()
        .join();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => [...array], [dataHash]);
}

/**
 * This hook returns a new array whenever the values of the elements of the input array change.
 * This can, for example, be helpful if you want to include an array in the dependency list of
 * another hook, but only want to cause a rerender if the content of the array changes
 * (not only the reference).
 * @param iterable
 */
export function useStableIdArray(iterable: Iterable<string> | ArrayLike<string>) {
    const dataHash = Array.from(iterable).sort().join();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => Array.from(iterable), [dataHash]);
}
