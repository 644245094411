import { useMemo } from "react";
import { DateRange } from "@/util/timeUtils";

export function useStableDateRange(dateRange: DateRange): DateRange {
    const from = dateRange[0].getTime();
    const to = dateRange[1].getTime();

    return useMemo(() => {
        return [new Date(from), new Date(to)];
    }, [from, to]);
}
