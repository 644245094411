import { calculateTimeTrackingAmount } from "@/util/calculationUtils";
import { GetEmployeeTimesSummaryParams } from "@/util/employeeUtils";
import { filterTimeTrackingsWithoutBreak } from "@/util/timeTrackingUtils";

export function _getEmployeeWorkedMinutes(params: GetEmployeeTimesSummaryParams): number {
    const { timeTrackings, overtimes } = params;

    const overtimeHours = overtimes.reduce((acc, prev) => {
        return acc + prev.amount;
    }, 0);

    const timeTrackingsWithoutBreaks = filterTimeTrackingsWithoutBreak(timeTrackings, params.appCompany);
    return calculateTimeTrackingAmount(timeTrackingsWithoutBreaks) + overtimeHours * 60;
}
