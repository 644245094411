class TimeFormatter {
    public formatFromHours(time: number) {
        const hoursInMinutes = time * 60;

        return this.formatFromMinutes(hoursInMinutes);
    }

    public formatFromMinutes(minutes: number) {
        const absoluteMinutes = Math.round(Math.abs(minutes));
        const hoursToPrint = Math.floor(absoluteMinutes / 60);
        const minutesToPrint = (absoluteMinutes % 60).toFixed(0);

        return `${minutes < 0 ? "-" : ""}${hoursToPrint}:${minutesToPrint.padStart(2, "0")} h`;
    }
}

export const timeFormatter = new TimeFormatter();
