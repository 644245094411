import { Activity } from "@farmact/model/src/model/Employee";
import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { DateCell } from "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/shared/DateCell/DateCell";
import { CurrentActivityTableCells } from "./TableCells/CurrentActivityTableCells";
import "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/shared/tableGroupRow.scss";

type TableGroupRowProps = ComponentPropsWithoutRef<"div"> & {
    activity: Activity;
};

export const TableGroupCurrentActivityRow = (props: TableGroupRowProps) => {
    const { activity, ...restProps } = props;

    return (
        <div {...restProps} className={clsx("table-group-row", props.className)}>
            <DateCell />
            <CurrentActivityTableCells activity={activity} />
        </div>
    );
};
