import { IonSegment, IonSegmentButton } from "@ionic/react";
import { ReactNode } from "react";
import "./faSegment.scss";

type FaSegmentProps = {
    value: string;
    onValueChange: (value: string) => void;
    options: { value: string; label: string | ReactNode; disabled?: boolean }[];
};

export function FaSegment(props: FaSegmentProps) {
    return (
        <IonSegment
            scrollable={true}
            className="fa-segment"
            value={props.value}
            onIonChange={(event: any) => {
                if (!event.detail.value) {
                    return;
                }
                props.onValueChange(event.detail.value);
            }}>
            {props.options.map(option => (
                <IonSegmentButton
                    className="fa-segment__button"
                    disabled={option.disabled}
                    value={option.value}
                    key={option.value}>
                    {option.label}
                </IonSegmentButton>
            ))}
        </IonSegment>
    );
}
