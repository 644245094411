import { Absence } from "@farmact/model/src/model/Absence";
import { Activity } from "@farmact/model/src/model/Employee";
import { Overtime } from "@farmact/model/src/model/Overtime";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import { clipAbsence } from "@/util/absenceUtils";
import { computed } from "@/util/functions";
import { getTimeTrackingOverlapsWithDay } from "@/util/timeTrackingUtils";
import { DateLike } from "@/util/timeUtils";
import { DayGroupedWorkTimes } from "./TableGroup/TableGroupContext";

dayjs.extend(isoWeek);

export function groupWorktimesByDay(params: {
    timeTrackings: TimeTracking[];
    overtimes: Overtime[];
    absences: Absence[];
    currentActivity: Activity | null;
    startDate: Dayjs;
    endDate: Dayjs;
}): DayGroupedWorkTimes[] {
    const { timeTrackings, overtimes, absences, currentActivity, startDate, endDate } = params;
    const groups: DayGroupedWorkTimes[] = [];
    const days = Math.max(endDate.diff(startDate, "days"), -1);
    for (let i = days; i >= 0; i--) {
        const startOfDay = startDate.startOf("day").add(i, "days");
        const endOfDay = startOfDay.endOf("day");
        const includeCurrentActivity = computed(() => {
            if (!currentActivity) {
                return false;
            }

            if (dayjs(currentActivity.startDateTime).isAfter(endOfDay)) {
                return false;
            }
            if (startOfDay.isAfter(dayjs(), "day")) {
                return false;
            }
            return true;
        });

        groups.push({
            day: startOfDay.toDate(),
            timeTrackings: sortTimeTrackingsByStartTime(
                timeTrackings.filter(timeTracking => {
                    return getTimeTrackingOverlapsWithDay(startOfDay.toDate(), timeTracking);
                })
            ),
            overtimes: overtimes.filter(overtime => dayjs(overtime.date).isSame(startOfDay, "day")),
            absences: absences.filter(absence => absence.dates.includes(startOfDay.format("YYYY-MM-DD"))),
            currentActivity: includeCurrentActivity ? currentActivity : null,
        });
    }

    return groups.filter(
        group =>
            group.currentActivity || group.timeTrackings.length + group.overtimes.length + group.absences.length > 0
    );
}

function sortTimeTrackingsByStartTime(timeTrackings: TimeTracking[]): TimeTracking[] {
    return [...timeTrackings].sort((a, b) => {
        return dayjs(a.startDateTime).valueOf() - dayjs(b.startDateTime).valueOf();
    });
}

export type CalendarWeekTimeTrackingSection = {
    id: string;
    week: Week | null;
    year: Year | null;
    groups: DayGroupedWorkTimes[];
};

export function createSimpleWorktimeSections(params: {
    timeTrackings: TimeTracking[];
    overtimes: Overtime[];
    absences: Absence[];
    startDate: Dayjs;
    endDate: Dayjs;
    currentActivity: Activity | null;
}): CalendarWeekTimeTrackingSection[] {
    return [
        {
            id: "simple-time-tracking-section",
            week: null,
            year: null,
            groups: groupWorktimesByDay(params),
        },
    ];
}

type Week = number;
type Year = number;
type WeekSplittedKey = `${Week}-${Year}`;

export function createWeekSplittedWorktimeSections(params: {
    timeTrackings: TimeTracking[];
    overtimes: Overtime[];
    absences: Absence[];
    currentActivity: Activity | null;
    startDate: Dayjs;
    endDate: Dayjs;
}): CalendarWeekTimeTrackingSection[] {
    const { timeTrackings, overtimes, absences, startDate, endDate } = params;
    const weekGroupedTimeTrackings = new Map<WeekSplittedKey, TimeTracking[]>();
    const weekGroupedOvertime = new Map<WeekSplittedKey, Overtime[]>();
    const weekGroupedAbsences = new Map<WeekSplittedKey, Absence[]>();
    const uniqueWeeks = new Set<WeekSplittedKey>();

    const days = Math.max(endDate.diff(startDate, "days"), -1);
    for (let i = 0; i <= days; i++) {
        const currentDay = startDate.add(i, "days");
        uniqueWeeks.add(getWeekSplittedKey(currentDay));
    }

    for (const timeTracking of timeTrackings) {
        const key = getWeekSplittedKey(dayjs(timeTracking.startDateTime));
        weekGroupedTimeTrackings.set(key, [...(weekGroupedTimeTrackings.get(key) ?? []), timeTracking]);
    }
    overtimes.forEach(overtime => {
        const key = getWeekSplittedKey(dayjs(overtime.date));
        weekGroupedOvertime.set(key, [...(weekGroupedOvertime.get(key) ?? []), overtime]);
    });
    absences.forEach(absence => {
        absence.dates.forEach(date => {
            const clippedAbsence = clipAbsence(absence, dayjs(date).startOf("week"), dayjs(date).endOf("week"));
            const key = getWeekSplittedKey(dayjs(date));
            if (!(weekGroupedAbsences.get(key) ?? []).find(absence => absence.id === clippedAbsence.id)) {
                weekGroupedAbsences.set(key, [...(weekGroupedAbsences.get(key) ?? []), clippedAbsence]);
            }
        });
    });
    const sections = Array.from(uniqueWeeks).map(key => {
        const [week, year] = key.split("-");
        const weekTimeTrackings = weekGroupedTimeTrackings.get(key) ?? [];
        const weekOvertimes = weekGroupedOvertime.get(key) ?? [];
        const weekAbsences = weekGroupedAbsences.get(key) ?? [];
        const sectionWeek = dayjs().year(Number(year)).isoWeek(Number(week));

        return {
            id: `${week}-${year}`,
            week: parseInt(week),
            year: parseInt(year),
            groups: groupWorktimesByDay({
                overtimes: weekOvertimes,
                absences: weekAbsences,
                timeTrackings: weekTimeTrackings,
                currentActivity: params.currentActivity,
                startDate: sectionWeek.startOf("week"),
                endDate: sectionWeek.endOf("week"),
            }),
        };
    });

    return sortTimeTrackingSections(sections);
}

function sortTimeTrackingSections(sections: CalendarWeekTimeTrackingSection[]): CalendarWeekTimeTrackingSection[] {
    return [...sections].sort((a, b) => {
        if (a.week === null || a.year === null) {
            return -1;
        } else if (b.week === null || b.year === null) {
            return 1;
        }

        const aDate = dayjs().year(a.year).isoWeek(a.week).toDate();
        const bDate = dayjs().year(b.year).isoWeek(b.week).toDate();

        return +bDate - +aDate;
    });
}

function getWeekSplittedKey(date: DateLike): WeekSplittedKey {
    const isoWeek = dayjs(date).isoWeek();
    const isoWeekYear = dayjs(date).isoWeekYear();
    return `${isoWeek}-${isoWeekYear}`;
}
