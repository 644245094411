export function trimZeros(value?: string | null) {
    let trimmedValue = value;
    while (
        trimmedValue &&
        trimmedValue.length >= 2 &&
        trimmedValue[0] === "0" &&
        ![",", "."].includes(trimmedValue[1])
    ) {
        trimmedValue = trimmedValue.substr(1);
    }
    return trimmedValue;
}
