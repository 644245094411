import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/de";
import { DayGroupedWorkTimes } from "@/components/organization/sharedComponents/TimeTrackingsTable/TableGroup/TableGroupContext";
import { computed } from "@/util/functions";

export function getFormattedStartOfDay(
    group: Pick<DayGroupedWorkTimes, "timeTrackings" | "currentActivity" | "day">
): string {
    const startAccordingToTimeTrackings = computed(() => {
        if (group.timeTrackings.length === 0) {
            return null;
        }

        const startSortedTrackings = [...group.timeTrackings].sort((a, b) => {
            return dayjs(a.startDateTime).diff(dayjs(b.startDateTime));
        });

        return dayjs(startSortedTrackings[0].startDateTime);
    });

    let startDate: Dayjs;
    if (!group.currentActivity || dayjs(group.day).isAfter(dayjs(), "day")) {
        startDate = startAccordingToTimeTrackings ?? dayjs(group.day).startOf("day");
    } else {
        const startOfCurrentActivity = dayjs(group.currentActivity.startDateTime);
        if (startOfCurrentActivity.isBefore(group.day, "day")) {
            startDate = dayjs(group.day).startOf("day");
        } else if (!startAccordingToTimeTrackings || startOfCurrentActivity.isBefore(startAccordingToTimeTrackings)) {
            startDate = startOfCurrentActivity;
        } else {
            startDate = startAccordingToTimeTrackings;
        }
    }
    return formatWorkTime(startDate);
}

export function getFormattedEndOfDay(
    group: Pick<DayGroupedWorkTimes, "timeTrackings" | "currentActivity" | "day">
): string {
    const endAccordingToTimeTrackings = computed(() => {
        if (group.timeTrackings.length === 0) {
            return null;
        }

        const endSortedTrackings = [...group.timeTrackings].sort((a, b) => {
            return dayjs(a.endDateTime).diff(dayjs(b.endDateTime));
        });

        return dayjs(endSortedTrackings[endSortedTrackings.length - 1].endDateTime);
    });

    let endDate: Dayjs;
    if (!group.currentActivity || dayjs(group.day).isAfter(dayjs(), "day")) {
        endDate = endAccordingToTimeTrackings ?? dayjs(group.day).endOf("day");
    } else {
        const startOfCurrentActivity = dayjs(group.currentActivity.startDateTime);
        if (!startOfCurrentActivity.isAfter(group.day, "day")) {
            if (dayjs().isSame(group.day, "day")) {
                return "offen";
            } else {
                return "23:59";
            }
        }
        endDate = endAccordingToTimeTrackings ?? dayjs(group.day).endOf("day");
    }
    return formatWorkTime(endDate);
}

function formatWorkTime(date: Dayjs): string {
    return date.format("HH:mm");
}
