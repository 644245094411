import { nullable, number, object, string } from "superstruct";

export type ScalePrice = {
    id: string; // uuid
    /**
     * Set to `null` to indicate that this scale price
     * should be used as the upper bound (e.g. 100 - ∞)
     */
    upperBound: number | null;
    price: number;
};

export const ScalePriceStruct = object({
    id: string(),
    upperBound: nullable(number()),
    price: number(),
});
