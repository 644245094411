import dayjs from "dayjs";
import { Address } from "./Address";
import { DatevTransfer } from "./DatevTransfer";
import { FreePositionGroup } from "./FreePositionGroup";
import { MailTraceMeta } from "./MailTraceMeta";
import { OperatingUnit } from "./OperatingUnit";
import { OrderPositionGroup } from "./OrderPositionGroup";
import { ServicePositionGroup } from "./ServicePositionGroup";

export type AnyReceiptPositionGroup = ServicePositionGroup | OrderPositionGroup | FreePositionGroup;

export abstract class Receipt {
    public id: string;
    public receiptNumber: string | null;
    public operatingUnitId: OperatingUnit["id"];
    public billingAddress: Address;
    public deliveryAddress: Address;
    public customerId: string;
    public customerNumber: number | null;
    public customerSalesTaxId: string;
    public customerContactPerson: string;
    /**
     * format YYYY-MM-DD, e.g. 2021-07-24
     */
    public date: string;
    public serviceDate: string;
    public positionGroups: AnyReceiptPositionGroup[];
    public archived: boolean;
    public intro: string;
    public outro: string;
    public contactPerson: string;
    public contactPhone: string;
    public contactEmail: string;
    public appendixPaths: string[];
    /**
     * List of enabled exports to append on the BillTemplate.
     * BillExports are presets and are meant to be generated just-in-time when the invoice is created.
     * @default []
     */
    public appendixExports: ReceiptExports[];
    public latestSentMailInfo: MailTraceMeta | null;
    public readonly datevTransfer: DatevTransfer | null;
    public buyerReference: string; // German "Leitweg-ID"

    public printDate: string | null; // ISO datetime
    public downloadDate: string | null; // ISO datetime

    protected constructor(initialValues?: Partial<Receipt>) {
        this.id = initialValues?.id ?? "";
        this.receiptNumber = initialValues?.receiptNumber ?? null;
        this.operatingUnitId = initialValues?.operatingUnitId ?? "";
        this.customerId = initialValues?.customerId ?? "";
        this.customerNumber = initialValues?.customerNumber ?? null;
        this.billingAddress = initialValues?.billingAddress ?? {
            name: "",
            street: "",
            countryCode: "DE",
            zip: "",
            city: "",
        };
        this.deliveryAddress = initialValues?.deliveryAddress ?? {
            name: "",
            street: "",
            countryCode: "DE",
            zip: "",
            city: "",
        };
        this.customerSalesTaxId = initialValues?.customerSalesTaxId ?? "";
        this.customerContactPerson = initialValues?.customerContactPerson ?? "";
        this.date = initialValues?.date ?? dayjs().format("YYYY-MM-DD");
        this.positionGroups = initialValues?.positionGroups ?? [];
        this.serviceDate = initialValues?.serviceDate ?? "";
        this.archived = initialValues?.archived ?? false;
        this.intro = initialValues?.intro ?? "";
        this.outro = initialValues?.outro ?? "";
        this.contactPerson = initialValues?.contactPerson ?? "";
        this.contactPhone = initialValues?.contactPhone ?? "";
        this.contactEmail = initialValues?.contactEmail ?? "";
        this.appendixPaths = initialValues?.appendixPaths ?? [];
        this.appendixExports = initialValues?.appendixExports ?? [];
        this.latestSentMailInfo = initialValues?.latestSentMailInfo ?? null;
        this.datevTransfer = initialValues?.datevTransfer ?? null;

        this.buyerReference = initialValues?.buyerReference ?? "";

        this.printDate = initialValues?.printDate ?? null;
        this.downloadDate = initialValues?.downloadDate ?? null;
    }
}

export enum ReceiptExports {
    ORDER_DELIVERY_NOTE = "ORDER_DELIVERY_NOTE",
    ORDER_PROTOCOL = "ORDER_PROTOCOL",
    ORDER_DOCUMENTS = "ORDER_DOCUMENTS",
    ORDER_PHOTOS = "ORDER_PHOTOS",
    ORDER_WEIGHINGS = "ORDER_WEIGHINGS",
    ORDER_ACTIVITY = "ORDER_ACTIVITY",
}

export type ReceiptBackendInfo = Pick<Receipt, "datevTransfer" | "latestSentMailInfo">;
