import { Employee } from "@farmact/model/src/model/Employee";
import { TimeTracking } from "@farmact/model/src/model/TimeTracking";
import dayjs from "dayjs";
import { Query } from "firebase/firestore";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import { useStableDateRange } from "@/util/customHooks/useStableDateRange";
import { ModelOrId, getModelId } from "@/util/select";
import { DateRange } from "@/util/timeUtils";

type UseEmployeeTimeTrackingsParams = {
    employee: ModelOrId<Employee> | undefined;
    interval: DateRange;
};

type UseEmployeeTimeTrackingsReturn = {
    loading: boolean;
    timeTrackings: TimeTracking[];
};

export function useEmployeeTimeTrackings(params: UseEmployeeTimeTrackingsParams): UseEmployeeTimeTrackingsReturn {
    const [start, end] = useStableDateRange(params.interval);

    const employeeId = params.employee ? getModelId(params.employee) : undefined;

    const [timeTrackings, loading] = useCollectionData(
        getQuery({
            employeeId,
            interval: [start, end],
        }),
        [employeeId, start, end]
    );

    return {
        loading,
        timeTrackings,
    };
}

type GetQueryParams = {
    employeeId: string | undefined;
    interval: DateRange;
};

function getQuery(params: GetQueryParams): Query<TimeTracking> | undefined {
    if (!params.employeeId) {
        return;
    }

    const [start, end] = params.interval;

    return Firebase.instance().getTimeTrackingsForEmployeeInDateRange(params.employeeId, dayjs(start), dayjs(end));
}
