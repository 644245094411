import { Absence } from "@farmact/model/src/model/Absence";
import { Employee } from "@farmact/model/src/model/Employee";
import dayjs from "dayjs";
import { query, where } from "firebase/firestore";
import { useMemo } from "react";
import { Firebase } from "@/firebase";
import { useCollectionData } from "@/firebase/dataHooks";
import { SnapshotGenerator, useMergedQueryData } from "@/firebase/utils/useMergedQueryData/useMergedQueryData";
import { getQueryableDatesInRange } from "@/util/customHooks/useOrdersInDateRange";
import { useStableDateRange } from "@/util/customHooks/useStableDateRange";
import { ModelOrId, getModelId } from "@/util/select";
import { DateRange } from "@/util/timeUtils";

type UseEmployeeAbsencesParams = {
    employee: ModelOrId<Employee> | undefined;
    interval: DateRange;
};

type UseEmployeeAbsencesReturn = {
    loading: boolean;
    absences: Absence[];
};

export function useAllEmployeeAbsences(employeeId: Employee["id"] | undefined) {
    return useCollectionData(employeeId ? Firebase.instance().getAllAbsencesForEmployee(employeeId) : undefined, [
        employeeId,
    ]);
}

export function useEmployeeAbsencesInInterval(params: UseEmployeeAbsencesParams): UseEmployeeAbsencesReturn {
    const [start, end] = useStableDateRange(params.interval);

    const employeeId = params.employee ? getModelId(params.employee) : undefined;

    const datesToQuery = useMemo(() => {
        return getQueryableDatesInRange(formatToQueryableDate(start), formatToQueryableDate(end));
    }, [start, end]);

    const snapshotGenerator = useMemo(() => {
        return getSnapshotGenerator(employeeId);
    }, [employeeId]);

    const [absences, loading] = useMergedQueryData(datesToQuery, snapshotGenerator);

    return {
        loading,
        absences,
    };
}

function formatToQueryableDate(date: Date): string {
    return dayjs(date).format("YYYY-MM-DD");
}

function getSnapshotGenerator(employeeId: string | undefined): SnapshotGenerator<string, Absence> | undefined {
    if (!employeeId) {
        return;
    }

    return dates => {
        return query(
            Firebase.instance().getAllAbsences(),
            where("employeeId", "==", employeeId),
            where("dates", "array-contains-any", dates)
        );
    };
}
