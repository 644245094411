import { Absence, AbsenceType } from "@farmact/model/src/model/Absence";
import { computeDates } from "@farmact/model/src/utils/computeDates";
import { IconDefinition, faBabyCarriage, faInfoCircle, faPlane } from "@fortawesome/free-solid-svg-icons";
import { faFaceThermometer, faPersonToDoor, faSchool } from "@fortawesome/pro-solid-svg-icons";
import dayjs, { Dayjs } from "dayjs";
import { getWorkDaysBetweenTwoDates } from "@/components/organization/sharedComponents/TimeTrackingsSummary/utils/getTargetHours";
import { DateLike } from "./timeUtils";

export function clipAbsences(absences: Absence[], startDate: Dayjs, endDate: Dayjs): Absence[] {
    const overlappingAbsences = absences.filter(absence => {
        return (
            !dayjs(absence.startDate).isAfter(endDate, "date") && !dayjs(absence.endDate).isBefore(startDate, "date")
        );
    });
    return overlappingAbsences.map(absence => clipAbsence(absence, startDate, endDate));
}

export function clipAbsence(absence: Absence, startDate: Dayjs, endDate: Dayjs): Absence {
    const clippedAbsence = { ...absence };
    if (dayjs(absence.startDate).isBefore(startDate, "date")) {
        clippedAbsence.startDate = startDate.startOf("date").toISOString();
    }
    if (dayjs(absence.endDate).isAfter(endDate, "date")) {
        clippedAbsence.endDate = endDate.endOf("date").toISOString();
    }
    clippedAbsence.dates = computeDates(clippedAbsence.startDate, clippedAbsence.endDate);
    return clippedAbsence;
}

export function getWorkDaysForAbsencesBetweenDates(absences: Absence[], start: DateLike, end: DateLike) {
    return clipAbsences(absences, dayjs(start), dayjs(end)).reduce((acc, curr) => {
        return acc + getWorkDaysBetweenTwoDates(new Date(curr.startDate), new Date(curr.endDate));
    }, 0);
}

export function getAbsenceTypeName(absenceType: AbsenceType) {
    switch (absenceType) {
        case AbsenceType.VACATION:
            return "Urlaub";
        case AbsenceType.SICK:
            return "Krankheit";
        case AbsenceType.OTHER:
            return "Sonstige Abwesenheit";
        case AbsenceType.PARENTAL_LEAVE:
            return "Elternzeit";
        case AbsenceType.TRAINING:
            return "Schulung";
        default:
            return "Abwesenheit";
    }
}

export function getAbsenceIcon(absenceType: AbsenceType): IconDefinition {
    switch (absenceType) {
        case AbsenceType.VACATION:
            return faPlane;
        case AbsenceType.SICK:
            return faFaceThermometer;
        case AbsenceType.PARENTAL_LEAVE:
            return faBabyCarriage;
        case AbsenceType.TRAINING:
            return faSchool;
        case AbsenceType.OTHER:
            return faPersonToDoor;
        default:
            return faInfoCircle;
    }
}
