import { Customer } from "@farmact/model/src/model/Customer";
import { ComboboxItem } from "@mantine/core";
import { EmptyMessageAccessor } from "@/components/inputs/SingleSelect/SingleSelect";
import { SelectOptionSorter } from "@/util/select";

export function createCustomerSelectItem(customer: Customer): ComboboxItem {
    return {
        value: customer.id,
        label: getCustomerSelectLabel(customer),
        disabled: !customer.active || customer.archived,
    };
}

export function getCustomerSelectLabel(customer: Customer): string {
    const name = customer.displayName;
    const alias = customer.alias ? `(${customer.alias})` : null;

    const lines = [name, alias];
    return lines.filter(Boolean).join(" ");
}

export const THRESHOLD_FOR_LOADING_CUSTOMERS = 300;

export function createCustomerSelectEmptyMessageAccessor(numberOfCustomers: number): EmptyMessageAccessor {
    const fallbackMessage = "Nichts gefunden";

    return search => {
        if (numberOfCustomers < THRESHOLD_FOR_LOADING_CUSTOMERS) {
            return fallbackMessage;
        }
        if (search.length >= 3) {
            return fallbackMessage;
        }

        return "Bitte gib mindestens 3 Zeichen ein";
    };
}

export const defaultSelectableCustomersSorter: SelectOptionSorter<Customer> = (a, b) => {
    return a.displayName.localeCompare(b.displayName);
};
