import { Customer } from "@farmact/model/src/model/Customer";
import { useState } from "react";
import { SingleSelect } from "@/components/inputs/SingleSelect/SingleSelect";
import { useCustomersCount } from "@/util/customHooks/customers/useCustomersCount";
import { SingleSelectProps, getModelId, getSingleSelectChangeHandler, useSelectOptions } from "@/util/select";
import {
    createCustomerSelectEmptyMessageAccessor,
    createCustomerSelectItem,
    defaultSelectableCustomersSorter,
} from "./customerInputsUtils";
import { useCustomersForCustomerSelect } from "./utils/useCustomersForCustomerSelect";

export type SingleCustomerSelectProps = SingleSelectProps<Customer> & {
    filter?: (customer: Customer) => boolean;
};

export const SingleCustomerSelect = (props: SingleCustomerSelectProps) => {
    const {
        placeholder = "Kunde auswählen",
        data: controlledCustomers,
        optionCreator = createCustomerSelectItem,
        sorter = defaultSelectableCustomersSorter,
        value,
        onValueChange,
        onModelChange,
        filter,
        ...restProps
    } = props;

    const { count: customersCount } = useCustomersCount();

    const [search, setSearch] = useState("");

    const [customers, loading] = useCustomersForCustomerSelect({
        controlledCustomers,
        currentSelection: value ? [getModelId(value)] : [],
        search,
        filter,
    });

    const items = useSelectOptions(customers, {
        optionCreator,
        sorter,
    });

    return (
        <SingleSelect
            {...restProps}
            loading={loading}
            placeholder={placeholder}
            data={items}
            value={value ? getModelId(value) : null}
            onChange={getSingleSelectChangeHandler(customers, {
                onValueChange,
                onModelChange,
            })}
            searchPlaceholder="Kunden durchsuchen"
            search={search}
            onSearchChange={setSearch}
            emptyMessage={createCustomerSelectEmptyMessageAccessor(customersCount)}
        />
    );
};
