import { Describe, array, enums, lazy, literal, nullable, number, object, optional, string, union } from "superstruct";
import { ScalePriceStruct } from "./PriceBlocks/ScalePrice";
import {
    AnyPriceStructure,
    AnyServicePriceBlock,
    PriceStructureType,
    SimplePriceStructure,
    TieredPriceStructure,
    VolumePriceStructure,
} from "./services/ServicePriceBlocks";

export type ResourcePrice<T extends ResourcePriceCalculationType> = {
    id: string;
    name: string;
    calculationType: T;
    variantsType: ResourcePriceVariantsType;
    costPerUnit?: number;
    pricePerUnit?: AnyResourcePriceStructure;
    surchargePercent?: number;
    vskz_mr: string | null;
    variants?: ResourcePrice<T>[];
};

export type ResourceTieredPriceStructure = TieredPriceStructure & {
    id: string;
};
export type ResourceVolumePriceStructure = VolumePriceStructure & {
    id: string;
};

export type AnyResourcePriceStructure =
    | SimplePriceStructure
    | ResourceTieredPriceStructure
    | ResourceVolumePriceStructure;

export type FixedResourcePrice = ResourcePrice<ResourcePriceCalculationType.FIXED>;

export type SurchargeResourcePrice = ResourcePrice<ResourcePriceCalculationType.SURCHARGE>;

export type SimpleResourcePrice<T extends ResourcePriceCalculationType> = ResourcePrice<T> & {
    variantsType: ResourcePriceVariantsType.SIMPLE;
    costPerUnit: number;
};

export type VariantsResourcePrice<T extends ResourcePriceCalculationType> = ResourcePrice<T> & {
    variantsType: ResourcePriceVariantsType.VARIANTS;
    variants: ResourcePrice<T>[];
};

export type SimpleFixedResourcePrice = SimpleResourcePrice<ResourcePriceCalculationType.FIXED> & {
    pricePerUnit: AnyResourcePriceStructure;
};

export type SimpleSurchargeResourcePrice = SimpleResourcePrice<ResourcePriceCalculationType.SURCHARGE> & {
    surchargePercent: number;
};

export type VariantsFixedResourcePrice = VariantsResourcePrice<ResourcePriceCalculationType.FIXED>;

export type VariantsSurchargeResourcePrice = VariantsResourcePrice<ResourcePriceCalculationType.SURCHARGE>;

export type ResourceVariantIdentifier = {
    resourceId: string;
    variantId: string;
};

export enum ResourcePriceCalculationType {
    FIXED = "FIXED",
    SURCHARGE = "SURCHARGE",
}

export enum ResourcePriceVariantsType {
    SIMPLE = "SIMPLE",
    VARIANTS = "VARIANTS",
}

export function isSimplePriceStructure(priceBlock: AnyPriceStructure | undefined): priceBlock is SimplePriceStructure {
    return priceBlock?.type === PriceStructureType.SIMPLE;
}

export function isTieredOrVolumePriceStruct(
    priceBlock: AnyServicePriceBlock["price"] | undefined
): priceBlock is TieredPriceStructure | VolumePriceStructure {
    return priceBlock?.type === PriceStructureType.TIERED || priceBlock?.type === PriceStructureType.VOLUME;
}

const SimplePriceStructureStruct: Describe<SimplePriceStructure> = object({
    type: literal(PriceStructureType.SIMPLE),
    price: number(),
});

const TieredPriceStructureStruct: Describe<ResourceTieredPriceStructure> = object({
    type: literal(PriceStructureType.TIERED),
    id: string(),
    price: array(ScalePriceStruct),
});

const ResourceVolumePriceStructureStruct: Describe<ResourceVolumePriceStructure> = object({
    type: literal(PriceStructureType.VOLUME),
    id: string(),
    price: array(ScalePriceStruct),
});

export const ResourcePriceStruct: Describe<ResourcePrice<ResourcePriceCalculationType>> = object({
    id: string(),
    name: string(),
    calculationType: enums(Object.values(ResourcePriceCalculationType)),
    variantsType: enums(Object.values(ResourcePriceVariantsType)),
    costPerUnit: optional(number()),
    pricePerUnit: optional(
        union([SimplePriceStructureStruct, TieredPriceStructureStruct, ResourceVolumePriceStructureStruct])
    ),
    surchargePercent: optional(number()),
    vskz_mr: nullable(string()),
    variants: optional(lazy(() => array(ResourcePriceStruct))),
});
