export type RoundingSettings = {
    roundingDirection: RoundingDirection;
    roundingPrecision: RoundingPrecision;
};

export type Rounding = {
    /**
     * null means no rounding in place
     */
    hours: RoundingSettings | null;
};

export enum RoundingDirection {
    UP = "RoundingDirection_UP",
    DOWN = "RoundingDirection_DOWN",
    NEAREST = "RoundingDirection_NEAREST",
}

export enum RoundingPrecision {
    QUARTER = "RoundingPrecision_0.25",
    HALF = "RoundingPrecision_0.5",
    FULL = "RoundingPrecision_1",
}

export function getRoundingDirection(value: string) {
    if (value === RoundingDirection.UP) {
        return RoundingDirection.UP;
    } else if (value === RoundingDirection.DOWN) {
        return RoundingDirection.DOWN;
    } else {
        return RoundingDirection.NEAREST;
    }
}

export function getRoundingPrecision(value: string) {
    if (value === RoundingPrecision.FULL) {
        return RoundingPrecision.FULL;
    } else if (value === RoundingPrecision.HALF) {
        return RoundingPrecision.HALF;
    } else {
        return RoundingPrecision.QUARTER;
    }
}
