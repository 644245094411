import { Employee } from "@farmact/model/src/model/Employee";
import { Role } from "@farmact/model/src/model/Role";
import { Query, query, where } from "firebase/firestore";
import { useUserContext } from "@/components/authentication/Session/useUserContext";
import { useAppCompanyContext } from "@/components/authentication/useAppCompanyContext";
import { Firebase } from "@/firebase";
import { useCount } from "@/util/customHooks/dataHooks/useCount";

type UseCustomersCountReturn = {
    count: number;
    loading: boolean;
};

export function useCustomersCount(): UseCustomersCountReturn {
    const { appCompany } = useAppCompanyContext();
    const { employee: loggedInEmployee } = useUserContext();

    const appCompanyId = appCompany?.id;

    const [count, loading] = useCount(getQuery(appCompanyId, loggedInEmployee), [appCompanyId, loggedInEmployee]);

    return {
        count,
        loading,
    };
}

function getQuery(appCompanyId: string | undefined, loggedInEmployee: Employee | undefined): Query | undefined {
    if (!appCompanyId) {
        return;
    }

    if (!loggedInEmployee) {
        return;
    }
    if (loggedInEmployee.role === Role.OWNER) {
        return Firebase.instance().getAllCustomers();
    }
    if (loggedInEmployee.operatingUnitIds.length === 0) {
        return Firebase.instance().getAllCustomers();
    }

    return query(
        Firebase.instance().getAllCustomers(),
        where("operatingUnitIds", "array-contains-any", loggedInEmployee.operatingUnitIds)
    );
}
