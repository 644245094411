import { Machine, MachineVariantIdentifier } from "@farmact/model/src/model/Machine";
import { Order } from "@farmact/model/src/model/Order";
import { getCurrentlyActiveMachineVariants, isOrderActive } from "@/util/orderUtils";

export function constructMachineVariantsForNewOrderDataEntry(
    order: Order,
    orderMachines: Machine[]
): MachineVariantIdentifier[] {
    if (isOrderActive(order)) {
        return getCurrentlyActiveMachineVariants(order);
    }

    const sortedActiveTime = [...order.activeTime].sort((activeTime1, activeTime2) =>
        activeTime1.start.localeCompare(activeTime2.start)
    );
    const latestActiveMachineVariants =
        sortedActiveTime.length > 0 ? sortedActiveTime[sortedActiveTime.length - 1].machineVariants : [];

    return orderMachines
        .filter(machine => order.machineIds.includes(machine.id))
        .map(machine => {
            const latestActiveMachineVariant = latestActiveMachineVariants.find(
                variant => variant.machineId === machine.id
            );

            if (latestActiveMachineVariant) {
                return latestActiveMachineVariant;
            }

            return {
                machineId: machine.id,
                variantId: machine.variants.length === 1 ? machine.variants[0].id : "",
            };
        });
}
